import { YAxis } from '../../utils';
import { ScanReviewMarginLineEditor } from './MarginMarking';
import { useScanReviewRecordsFactory, useCompositeViewManager } from './ScanReview.hooks';
import type { ScanReviewOnInsertionAxisUpdateCallback, ScanReviewOnMarginUpdateCallback } from './ScanReview.utils';
import type { ScanReviewAppState, ScanReviewInitialAppState } from './ScanReviewApp.types';
import { ScanReviewAppStateManager } from './ScanReviewAppStateManager';
import type { ScanReviewInsertionAxis } from './ScanReviewDesignTypes';
import type { ScanReviewMarginLine } from './ScanReviewDesignTypes';
import type { ScanReviewRecordFactory } from './ScanReviewRecordTypes';
import { ScanReviewCompositeScene } from './ScanReviewSceneTypes';
import {
    type ScanReviewViewState,
    ScanReviewDisplayType,
    INITIAL_SCAN_REVIEW_BITE_ANALYSIS_HEATMAP_RANGE,
    INITIAL_SCAN_REVIEW_UNDERCUT_HEATMAP_RANGE,
    ScanReviewMode,
    ScanReviewViewType,
    DEFAULT_UNDERCUT_SHADING_RADIUS,
} from './ScanReviewViewTypes';
import { type ToothNumber } from '@orthly/items';
import React from 'react';

interface ScanReviewCompleteViewAppProps {
    lowerJawFactory: ScanReviewRecordFactory | null;
    upperJawFactory: ScanReviewRecordFactory | null;
    viewState: ScanReviewViewState;
    toothNumber?: ToothNumber;
    marginLines: ScanReviewMarginLine[];
    insertionAxes: ScanReviewInsertionAxis[];
    onMarginUpdate?: ScanReviewOnMarginUpdateCallback;
    onInsertionAxisUpdate?: ScanReviewOnInsertionAxisUpdateCallback;
}

export const DEFAULT_UPPER_JAW_INSERTION_AXIS: ScanReviewInsertionAxis = {
    unn: 1,
    direction: YAxis.clone(),
    maxDistance: DEFAULT_UNDERCUT_SHADING_RADIUS,
};

export const DEFAULT_LOWER_JAW_INSERTION_AXIS: ScanReviewInsertionAxis = {
    unn: 32,
    direction: YAxis.clone().negate(),
    maxDistance: DEFAULT_UNDERCUT_SHADING_RADIUS,
};

export function useScanReviewCompleteViewApp({
    lowerJawFactory,
    upperJawFactory,
    viewState,
    toothNumber,
    marginLines,
    insertionAxes,
    onMarginUpdate,
    onInsertionAxisUpdate,
}: ScanReviewCompleteViewAppProps) {
    const scanRecordsFactory = useScanReviewRecordsFactory(lowerJawFactory, upperJawFactory);
    const scene = React.useMemo(() => {
        return new ScanReviewCompositeScene(scanRecordsFactory);
    }, [scanRecordsFactory]);

    // The app state manager is responsible for synchronizing and managing the state of the scan review application
    // Important to note: It is write only - all changes made to state via this manager are communicated to React
    // via a callback that is passed into it's constructor. In this way we can separate our view layer from our
    // business logic.
    const defaultAppState: ScanReviewInitialAppState = React.useMemo(() => {
        return {
            mode: ScanReviewMode.Review,
            displayType: ScanReviewDisplayType.Scan,
            viewType: ScanReviewViewType.Complete,
            heatmapRange: INITIAL_SCAN_REVIEW_BITE_ANALYSIS_HEATMAP_RANGE,
            undercutHeatmapRange: INITIAL_SCAN_REVIEW_UNDERCUT_HEATMAP_RANGE,
            upperJawVisible: true,
            lowerJawVisible: true,
            isScanOrScanUndercutDisplay: true,
            isStoneModelOrStoneModelUndercutDisplay: false,
            insertionAxisIsVisible: true,
            marginIsVisible: true,
            tubeMarginLinesEnabled: true,
            toothNumber,
            marginSketchingModeActive: false,
        };
    }, [toothNumber]);

    // The view manager is responsible for the following:
    // 1. Tracking references to HTML canvas elements for all view panels
    // 2. Tracking references to THREE.JS camera objects for all view panels
    // 3. Tracking references to React Three Fiber camera controls for all view panels
    // 4. Adding/removing event listeners to the camera controls associated with a view panel to persist/restore view-state
    //    between component mount/unmount
    const viewManager = useCompositeViewManager(scene, viewState);

    const setScanReviewAppStateRef = React.useRef<(scanReviewAppState: ScanReviewAppState) => void>();
    const { app: scanReviewAppStateManager, appState } = React.useMemo(
        () =>
            ScanReviewAppStateManager.create(
                scene,
                viewManager,
                new Map(marginLines.map(m => [m.unn, new ScanReviewMarginLineEditor(m)])),
                new Map(insertionAxes.map(i => [i.unn, i])),
                {
                    setAppStateRef: setScanReviewAppStateRef,
                    onMarginUpdate,
                    onInsertionAxisUpdate,
                },
                defaultAppState,
            ),
        [defaultAppState, insertionAxes, marginLines, onInsertionAxisUpdate, onMarginUpdate, scene, viewManager],
    );

    const [scanReviewAppState, setScanReviewAppState] = React.useState<ScanReviewAppState>(appState);
    setScanReviewAppStateRef.current = setScanReviewAppState;

    return {
        scanReviewAppState,
        scanReviewAppStateManager,
    };
}
