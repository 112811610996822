import { type ScanReviewRecordFactory } from './ScanReviewRecordTypes';
import { type DcmManager } from '@orthly/forceps';
import type { Jaw } from '@orthly/shared-types';
import type React from 'react';
import type * as THREE from 'three';

export interface ScanReviewDcmFile {
    name: string;
    dcmManager: DcmManager;
    jawType: Jaw;
}

export interface ScanReviewDcmFileData {
    dcmFile: ScanReviewDcmFile;
    geometry: THREE.BufferGeometry | null;
    textureData: string | null;
}

function getGeometryFromDcm(dcmManager: DcmManager): THREE.BufferGeometry {
    const geometry = dcmManager.buildGeometry({ applyTextureCoords: true });
    geometry.computeVertexNormals();
    return geometry;
}

export function extractScanReviewDcmFileData(
    jawType: Jaw,
    dcmFiles: ScanReviewDcmFile[],
): ScanReviewDcmFileData | null {
    const dcmFile = dcmFiles.find(dcm => dcm.jawType === jawType);
    if (!dcmFile) {
        return null;
    }
    const { dcmManager } = dcmFile;
    const geometry = getGeometryFromDcm(dcmManager);
    const textureImage = dcmManager.parseTextureImages()[0] ?? null;
    const textureData = textureImage ? textureImage.b64Data : null;

    return {
        dcmFile,
        geometry,
        textureData,
    };
}

export function loadScanReviewDcmFileData(
    jaw: Jaw,
    jawData: ScanReviewDcmFileData,
    onLoadCallback: (jaw: Jaw, geometry: THREE.BufferGeometry, image: HTMLImageElement) => ScanReviewRecordFactory,
    factorySetter: (value: React.SetStateAction<ScanReviewRecordFactory | null>) => void,
) {
    if (!jawData.geometry || !jawData.textureData) {
        return;
    }
    const scanImage = new Image();
    const scanGeometry = jawData.geometry.clone();
    const imageUri = `data:image/jpeg;base64,${jawData.textureData}`;
    scanImage.onload = () => {
        factorySetter(() => onLoadCallback(jaw, scanGeometry, scanImage));
    };
    scanImage.src = imageUri;
}
