import type { ColorRampData } from '../ColorRamp';
import { HIGHLIGHT_TOOTH_COLOR } from '../ModelViewer';
import { createScanSegmentationMaterial } from '../ModelViewer/materials/ScanSegmentationShader';
import {
    createChairSideScanMeshShaderMaterial,
    createChairSideScanMeshShader,
} from '../ModelViewer/materials/scanMeshShaderMaterialChairside';
import { createScanMeshStoneMaterial } from './ScanReview.utils';
import type { ScanReviewInsertionAxis } from './ScanReviewDesignTypes';
import {
    ScanReviewHeatmapMaterialManager,
    ScanReviewUndercutMaterialManager,
    type ScanReviewInsertionDepthMapGeneratorFactory,
} from './ScanReviewMaterialTypes';
import { ScanReviewRecord } from './ScanReviewRecordTypes';
import { AttributeName } from '@orthly/forceps';
import * as THREE from 'three';

export class ScanReviewRecordBuilder {
    private readonly scanMesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material>;
    private scanMeshTexture: THREE.Texture;
    private readonly scanMeshMaterial: THREE.Material;
    private scanMeshHeatMapManager?: ScanReviewHeatmapMaterialManager;
    private scanMeshUndercutManager?: ScanReviewUndercutMaterialManager;
    private scanMeshStoneMaterial?: THREE.Material;
    private scanMeshStoneUndercutManager?: ScanReviewUndercutMaterialManager;
    private scanSegmentationMaterial?: THREE.Material;

    private readonly n1 = [0, 0, 1, 0];
    private readonly n2 = [0, 1, 0, 0];
    private readonly n3 = [1, 0, 0, 0];
    private readonly n4 = [0, 0, 0, 1];
    //This permutation matrix is to reverse the rgb components of the texture packed into
    //the DCM file.
    private readonly bgrSwap = new THREE.Matrix4().fromArray([...this.n1, ...this.n2, ...this.n3, ...this.n4]);

    constructor(
        private scanGeometry: THREE.BufferGeometry,
        private scanImage: HTMLImageElement,
        private colorRampData?: ColorRampData | undefined,
        private insertionDepthMapGeneratorFactory?: ScanReviewInsertionDepthMapGeneratorFactory | undefined,
    ) {
        this.scanMeshTexture = new THREE.Texture(this.scanImage);
        this.scanMeshTexture.flipY = false;
        this.scanMeshTexture.needsUpdate = true;

        this.scanMeshMaterial = createChairSideScanMeshShaderMaterial(
            createChairSideScanMeshShader({ bgrSwap: this.bgrSwap, map: this.scanMeshTexture }),
        );

        this.scanMeshMaterial.needsUpdate = true;
        this.scanMesh = new THREE.Mesh<THREE.BufferGeometry, THREE.Material>(this.scanGeometry, this.scanMeshMaterial);
    }

    buildScanSegmentationShader(): ScanReviewRecordBuilder {
        this.scanSegmentationMaterial = createScanSegmentationMaterial({
            side: THREE.DoubleSide,
        });
        return this;
    }

    buildDcmScanStoneMaterial(): ScanReviewRecordBuilder {
        this.scanMeshStoneMaterial = createScanMeshStoneMaterial();
        return this;
    }

    buildDcmScanStoneUndercutManager(insertionAxis: ScanReviewInsertionAxis): ScanReviewRecordBuilder {
        if (!this.insertionDepthMapGeneratorFactory) {
            console.warn(
                'No insertion depth map generator provided to ScanReviewDcmBuilder instance.\n',
                '\tCannot generate depth maps.',
            );
            return this;
        }

        const stoneUndercutMaterial = createScanMeshStoneMaterial();
        this.scanMeshStoneUndercutManager = new ScanReviewUndercutMaterialManager(
            this.scanGeometry,
            stoneUndercutMaterial,
            insertionAxis,
            stoneUndercutMaterial.color,
            this.colorRampData?.greenToRed.texture,
            this.insertionDepthMapGeneratorFactory,
        );
        return this;
    }

    buildDcmScanUndercutManager(insertionAxis: ScanReviewInsertionAxis): ScanReviewRecordBuilder {
        if (!this.insertionDepthMapGeneratorFactory) {
            console.warn(
                'No insertion depth map generator provided to ScanReviewDcmBuilder instance.\n',
                '\tCannot generate depth maps.',
            );
            return this;
        }

        const shader = createChairSideScanMeshShader({ bgrSwap: this.bgrSwap, map: this.scanMeshTexture });
        const undercutMaterial = createChairSideScanMeshShaderMaterial({ ...shader });
        this.scanMeshUndercutManager = new ScanReviewUndercutMaterialManager(
            this.scanGeometry,
            undercutMaterial,
            insertionAxis,
            new THREE.Color(HIGHLIGHT_TOOTH_COLOR),
            this.colorRampData?.greenToRed.texture,
            this.insertionDepthMapGeneratorFactory,
        );
        return this;
    }

    buildDcmScanHeatmapManager(): ScanReviewRecordBuilder {
        this.scanMeshHeatMapManager = new ScanReviewHeatmapMaterialManager();
        return this;
    }

    buildDcmVertexColors(): ScanReviewRecordBuilder {
        const canvas = document.createElement('canvas');
        canvas.width = this.scanImage.width;
        canvas.height = this.scanImage.height;
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            console.warn('Warning, could not obtain html canvas context for baking DCM vertex colors.');
            return this;
        }
        ctx.drawImage(this.scanImage, 0, 0, this.scanImage.width, this.scanImage.height);
        const imageData = ctx.getImageData(0, 0, this.scanImage.width, this.scanImage.height);

        // Each vertex is "mapped" to a corresponding point on the texture via uv coordinates.
        // In this scheme, u and v are both numbers between [0, 1], which refer to an x, y position in the texture image.
        // Since there are two values per vertex, we can find the vertex count by dividing by 2.
        // We will create a color map of a r, g, and b byte for each vertex, hence needing vertexCount * 3.
        const uvs = this.scanGeometry.getAttribute(AttributeName.TexCoord).array;
        const vertexCount = uvs.length / 2;
        const colorMap = new Float32Array(vertexCount * 3);

        // Calculate the vertex color for each vertex.
        // vertexCount is generally about 100,000-200,000 for most of the scans we get.
        // This would be another good candidate for optimization if we run into speed issues, though in testing it ran pretty fast.
        for (let i = 0; i < vertexCount; i++) {
            const u = uvs[2 * i] ?? 0;
            const v = uvs[2 * i + 1] ?? 0;

            // u = 0 implies the left side of the image, u = 1 implies the right side.
            // v = 0 implies the top of the image, v = 1 implies the bottom edge.
            const xPos = Math.floor(this.scanImage.width * u);
            const yPos = Math.floor(this.scanImage.height * v);
            const idx = (this.scanImage.width * yPos + xPos) * 4;

            // Out of bounds checks.
            if (
                xPos < 0 ||
                yPos < 0 ||
                xPos >= this.scanImage.width ||
                yPos >= this.scanImage.height ||
                idx + 2 >= imageData.data.length
            ) {
                continue;
            }

            const r = (imageData.data[idx + 2] ?? 0) / 255;
            const g = (imageData.data[idx + 1] ?? 0) / 255;
            const b = (imageData.data[idx] ?? 0) / 255;
            colorMap.set([r, g, b], i * 3);
        }
        this.scanGeometry.setAttribute(AttributeName.Color, new THREE.Float32BufferAttribute(colorMap, 3));

        return this;
    }

    complete(): ScanReviewRecord | undefined {
        const scanReviewRecord =
            this.scanMesh &&
            this.scanMeshTexture &&
            this.scanMeshMaterial &&
            new ScanReviewRecord(
                this.scanMesh,
                this.scanMeshTexture,
                this.scanMeshMaterial,
                this.scanMeshStoneMaterial,
                this.scanMeshHeatMapManager,
                this.scanMeshUndercutManager,
                this.scanMeshStoneUndercutManager,
                this.scanSegmentationMaterial,
            );
        return scanReviewRecord;
    }
}
