/* eslint-disable */
/* NOTE: THIS IS AN AUTOGENERATED FILE, DO NOT EDIT */
/* orthlyweb graphql/types documentation: https://docs.dandyserv.net/orthlyweb/local-development/graphql/index.html */
export const GqlOperationReturnTypes: Record<string, string> = {
    actionTypesWithCategoryInfo: 'ActionTypeWithCategory',
    active_price_presets: 'IPricePreset',
    alignerPhotoUploaded: 'Boolean',
    areItemsEligibleForLiveDesignReview: 'Boolean',
    biasRulesForDoctor: 'BiasRule',
    bulkLoadPracticePrices: 'PracticePricesResult',
    calculateCreditCardProcessingFeeForAmount: 'Int',
    calculateCreditCardProcessingFeeForInvoiceAmounts: 'CreditCardFeePerInvoiceDTO',
    calculatePracticeInvoicedSpendOverPeriod: 'OrgAggregateSpendForPeriodDTO',
    calendlyGetNextAvailableLdrTime: 'CalendlyGetNextAvailableLdrTimeResponse',
    canDeleteDeliveryAddress: 'Boolean',
    capacityRulesForManufacturer: 'CapacityRule',
    checkCsSchedule: 'CheckCsScheduleResult',
    checkPracticeAdmins: 'Boolean',
    checkScannerConnected: 'ScannerResponse',
    debugDesignerAutoAssignment: 'AutoAssignerDebuggerInfo',
    debugRoutingDecision: 'RoutingDecisionEvent',
    deleteFile: 'String',
    designRulesForManufacturer: 'DesignRule',
    designStaffDetailedStatus: 'DesignStaffDetailedStatus',
    designStaffStatusBulk: 'DesignStaffStatusEntry',
    estimatedDeliveryDate: 'EstimatedDeliveryDateRes',
    exchangeLegacyToken: 'String',
    exchangeMultiAuthLegacyToken: 'LegacyTokenResult',
    fetchAllPracticeEmployees: 'DoctorPreferences',
    fetchDoctors: 'DoctorPreferences',
    fetchOverdueInvoices: 'Invoice',
    fetchPartnerBillingChangelog: 'PartnerBillingChangelogEntry',
    fetchPracticeStaff: 'DoctorPreferences',
    fetchPracticeStaffUsers: 'UserStaff',
    fetchStaffForSelectionScreen: 'StaffForSelection',
    filteredTickets: 'TicketWithPracticeAndOrderInfo',
    findCRMTickets: 'CRMTicket',
    findCRMUsers: 'CRMUser',
    findLastChatForEntity: 'ChatMessageDTO',
    getAbutmentPart: 'AbutmentPartDTO',
    getActionCapabilities: 'ActionCapabilityDTO',
    getActionCategories: 'ActionCategoryDTO',
    getActionCategoryById: 'ActionCategoryDTO',
    getActionTypeById: 'ActionTypeDTO',
    getActionTypes: 'ActionTypeDTO',
    getActiveContractAndAssociatedOrgsByOrganizationId: 'ContractAndAssociatedPracticesDTO',
    getActiveInternalDesignTask: 'ActiveInternalDesignTaskSummary',
    getActiveInternalDesignTasks: 'ActiveInternalDesignTaskSummary',
    getActiveScanReviewByLabOrderId: 'ScanReviewWorkOrderDTO',
    getActiveVideoDdpReviewRequest: 'VideoDdpReviewRequestDTO',
    getAlignerRetainerSummaryForOrder: 'AlignerCaseRetainersSummaryDTO',
    getAlignerTreatmentPlan: 'AlignerTreatmentPlan',
    getAllActiveCapacityStationPools: 'CapacityStationPoolDTO',
    getAllBillingCreditCategories: 'BillingCreditCategory',
    getAllCadSmileLibraries: 'CadSmileLibraryDto',
    getAllCapacityStationRules: 'CapacityStationRuleDTO',
    getAllCapacityStations: 'CapacityStationDTO',
    getAllDesignOrderAlignerTreatmentPlans: 'DesignOrderAlignerTreatmentPlanDTO',
    getAllDesignOrderDesignRevisions: 'DesignOrderDesignRevisionDTO',
    getAllOrganizationsCsv: 'GetAllOrganizationsCsvResult',
    getAllPendingVariantFeedback: 'VariantFeedbackWithOrderInfoDTO',
    getAllPendingVariantFeedbackForPractice: 'VariantFeedbackDTO',
    getAllPracticeContactsIncludingExternal: 'PracticeContact',
    getAllPreferenceSets: 'DoctorPreferences',
    getAllProductStationUnitMappings: 'ProductStationUnitMappingDTO',
    getAllScanners: 'AllScannersResponse',
    getAllScannersWithSearch: 'AllScannersResponse',
    getBatchShipManifest: 'BatchShipManifestDTO',
    getBillingContactEmailsForInvoices: 'BillingPrimaryContactEmail',
    getBillingCreditCategoriesById: 'BillingCreditCategory',
    getCRMChatConversation: 'CRMChatConversation',
    getCRMChatUser: 'CRMChatUser',
    getCRMPractice: 'CRMPractice',
    getCRMPracticeMetadata: 'PracticeMetadata',
    getCRMTicket: 'CRMTicket',
    getCRMTicketCommentAttachment: 'String',
    getCRMTicketComments: 'CRMTicketComments',
    getCadSmileLibraryById: 'CadSmileLibraryDto',
    getCalendlyEventTime: 'CalendlyEventTimeResponse',
    getCalendlySchedulingUrl: 'String',
    getCanceledLabOrders: 'CanceledLabOrderResult',
    getCapacityRuleLoads: 'CapacityRuleLoadResult',
    getCartDeliveryOptions: 'GetCartDeliveryOptionsRes',
    getCaseReadiness: 'CaseReadinessDTO',
    getCaseReadinessCases: 'CaseReadinessDTO',
    getChairsideFirebaseDownloadUrl: 'ChairsideSignedDownloadUrl',
    getChairsideFirebaseUploadUrl: 'ChairsideUpload',
    getCompletedStagesForOrder: 'ManufacturingStageDTO',
    getConnectedPreferencesByEmailAndPhone: 'DoctorPreferences',
    getContractAndAssociatedOrgsByOrganizationId: 'ContractAndAssociatedPracticesDTO',
    getCreditById: 'InvoiceCreditDTO',
    getCreditCardProcessingFeeAmountCentsForInvoice: 'Int',
    getCreditCardProcessingFeeAmountCentsForInvoiceIds: 'CreditCardFeePerInvoiceDTO',
    getDesignFileDmeList: 'DesignFileDmeListResult',
    getDesignOrderDesignRevisionById: 'DesignOrderDesignRevisionDTO',
    getDesignOrderDesignRevisionsByTaskIds: 'DesignOrderDesignRevisionDTO',
    getDesignQaEvaluationById: 'DesignQaEvaluationDTO',
    getDesignQaEvaluationsByOrderId: 'DesignQaEvaluationDTO',
    getDesignStaff: 'DesignStaffDTO',
    getDesignStaffFocusAreasByDesigner: 'DesignStaffFocusAreaDTO',
    getDesignStaffTeams: 'DesignStaffTeamDTO',
    getDesignTrainingEnrolledCourse: 'EnrolledCourse',
    getDoctorPreferencesById: 'DoctorPreferences',
    getDoctorPreferencesByToken: 'DoctorAndLastName',
    getDoctorRequest: 'DoctorRequestDTO',
    getDoesUserPreferPasswordLogin: 'Boolean',
    getEmailPreviewByNotificationLogId: 'GetEmailPreviewByNotificationLogIdResult',
    getFeatureFlagValueByOrganizationId: 'GetFeatureFlagValueByOrganizationIdResponse',
    getFirebaseToken: 'String',
    getFulfilledVideoDdpReviewRequest: 'VideoDdpReviewRequestDTO',
    getFullyResolvedPracticeContractsV2ByPracticeId: 'FullyResolvedPracticeContractDTO',
    getHoldRemovalSuggestedStageEndDate: 'DateTime',
    getImpersonationToken: 'ImpersonationTokenDTO',
    getImplantById: 'ImplantTypeDTO',
    getImplantTypes: 'ImplantTypeDTO',
    getImplantTypesLite: 'ImplantTypeDTO',
    getInjectionMoldFiles: 'String',
    getInvoiceById: 'InvoiceDTO',
    getInvoiceHistoryItems: 'PricingHistoryItemDTO',
    getIsFcmTrainingComplete: 'Boolean',
    getIsPracticeByod: 'Boolean',
    getIteroCompaniesByPracticeIds: 'IteroCompanyEntity',
    getIteroCompanyByPracticeId: 'IteroCompanyEntity',
    getIteroIntegrationAuthDetails: 'GetIteroIntegrationAuthDetails',
    getIteroSettingsOfCurrentUser: 'IteroUserSettings',
    getIteroUserByStaffId: 'IteroUserEntity',
    getIteroUsersByStaffIds: 'IteroUserEntity',
    getLabAssignmentsByOrderId: 'LabCaseAssignmentsByOrderIdResult',
    getLabCaseCompletion: 'LabCaseCompletionDTO',
    getLabCaseLedgerData: 'LabPriceLedgerResult',
    getLabCaseLedgerSummary: 'LabPriceLedgerSummaryResult',
    getLabMetalCost: 'LabMetalCostQueryDTO',
    getLabPriceDefinitions: 'LabPriceDefinitionResult',
    getLabPriceLabPrices: 'LabPriceLabPricesResult',
    getLabPricePartnerPrices: 'LabPricePartnerPricesResult',
    getLabPrices: 'LabPriceDTO',
    getLabSalesOrderById: 'LabSalesOrderDTO',
    getLabSalesOrderIdByOrderNumber: 'LabSalesOrderIdOnlyDTO',
    getLabSalesOrdersBySplitId: 'LabSalesOrderDTO',
    getLabSalesOrdersInRefabChain: 'LabSalesOrderDTO',
    getLabsPaymentMethods: 'StripePaymentMethod',
    getLastInvoiceEmailForOrganization: 'BillingSummaryEmail',
    getLatestDesignOrderDesignRevision: 'DesignOrderDesignRevisionDTO',
    getLatestDesignOrderDesignRevisionsForOrders: 'DesignOrderDesignRevisionDTO',
    getLatestDoctorVisibleDesignOrderAlignerTreatmentPlan: 'DesignOrderAlignerTreatmentPlanDTO',
    getLatestManufacturerFilesForOrders: 'DesignManufacturerFileResult',
    getLatestMergeAndStitchFilesForOrder: 'DesignMergeAndStitchFileResult',
    getLdrCapacityStats: 'LdrCapacityStatsResponse',
    getLegacyMultiFulfillmentInfoByOrderId: 'LegacyMultiFulfillmentInfoDTO',
    getLinkedDoctorPreferences: 'DoctorAndLastName',
    getLiveDdpReviewRequest: 'LiveDdpReviewRequestDto',
    getLiveLabTechMeetingUrl: 'LiveLabTechMeetingUrl',
    getLoginOptions: 'UserLoginOption',
    getManufacturerPreferencesPreferences: 'ManufacturerNotificationPreferences',
    getManufacturerProfile: 'ManufacturerProfile',
    getManufacturingBookingById: 'ManufacturingBookingDto',
    getMembershipLogin: 'UserLoginOption',
    getMetalMaterial: 'MetalMaterialDTO',
    getMostFrequentlyUsedImplantManufacturers: 'String',
    getMyPendingDesignQaEvaluations: 'DesignQaEvaluationDTO',
    getNextInvoiceStatus: 'NextInvoiceStatusSummary',
    getNextInvoiceStatusForContractOrgs: 'NextInvoiceStatusSummaryForOrganization',
    getNextPartnerOrderNumber: 'Float',
    getNonDoctorStaffById: 'NonDoctorPracticeStaffDTO',
    getNonDoctorStaffByPartnerId: 'NonDoctorPracticeStaffDTO',
    getNonFavoritedSavedSearches: 'SavedSearch',
    getNotificationLogsWithEvents: 'NotificationLogWithEventsArrayResult',
    getOnboardingSteps: 'GetOnboardingStepsResponse',
    getOnboardingStepsV2: 'GetOnboardingStepsResponseV2',
    getOrCreatePracticeSupport: 'PracticeSupportDTO',
    getOrderAdminListViewEntriesByIds: 'OrderAdminListViewEntry',
    getOrderAutomateResult: 'ThreeshapeAutomateCaseResult',
    getOrderByQcSearch: 'LabOrderWithEvents',
    getOrderHasPendingPayment: 'Boolean',
    getOrderIdByStackCode: 'StackCodeOrderDetails',
    getOrderIdFromOrderNumber: 'String',
    getOrderLedger: 'OrderInvoicingLedgerDTO',
    getOrderPracticeListViewEntriesByIds: 'OrderPracticeListViewEntry',
    getOrderPriceEntitiesByOrderId: 'OrderPriceEntityDTO',
    getOrderPriceEntitiesByOrderIds: 'OrderPriceEntityDTO',
    getOrderPriceHistoryItems: 'PricingHistoryItemDTO',
    getOrderPricesByOrderId: 'OrderPriceDTO',
    getOrderSlaChanges: 'OrderSlaChangeEntry',
    getOrderSlaStageStatuses: 'SLAStageStatus',
    getOrderTicketById: 'TicketDTO',
    getOrderTickets: 'TicketDTO',
    getOrderTrackerEntries: 'OrderTrackerStepDefinition',
    getOrderTrackingHoldMessage: 'String',
    getOrderTrackingHoldTitle: 'String',
    getOrdersForManufacturer: 'LabOrderWithEvents',
    getOrdersForShipment: 'LabOrderWithEvents',
    getOrgAdmins: 'StaffMemberWithRelations',
    getOrgPrimaryAdmin: 'StaffMemberWithRelations',
    getOrganization: 'OrganizationDTO',
    getOrganizationByExternalAccount: 'OrganizationDTO',
    getOrganizationPriceConfigs: 'OrganizationPriceConfigDTO',
    getOrganizationWithAncestors: 'OrganizationWithAncestors',
    getOrganizationWithDescendants: 'OrganizationWithDescendants',
    getOrganizationWithDescendantsAndStaff: 'OrganizationWithDescendants',
    getOrganizationsForUser: 'UserOrganizationDTO',
    getOutstandingBalanceCents: 'Float',
    getOwnImpersonatedShortenedLink: 'GetOwnImpersonatedShortenedLinkResponse',
    getOwnImpersonationToken: 'ImpersonationTokenDTO',
    getPaginatedOrganizations: 'PaginatedOrganizationsResponse',
    getPaginatedUsers: 'PaginatedUsersResponse',
    getPartnerBillingAccount: 'IPartnerBilling',
    getPartnerBillingAccountsForRelevantContract: 'IPartnerBilling',
    getPartnerPreferenceSets: 'DoctorPreferences',
    getPartnerPreferences: 'PartnerNotificationPreferences',
    getPartnerScanners: 'AllScannersResponse',
    getPatient: 'PatientDTO',
    getPatientDetails: 'PatientDetails',
    getPatientSharesByOrgId: 'GetPatientSharesByOrgIdResult',
    getPerfectShadeAppLaunch: 'PerfectShadeAppLaunch',
    getPracticeContractV2ByOrganizationId: 'PracticeContract',
    getPracticeDetails: 'PracticeDetails',
    getPracticeEquipmentData: 'EquipmentTrackerSnapshot',
    getPracticeInteractions: 'PracticeInteractions',
    getPracticeOnboarding: 'PracticeOnboardingDTO',
    getPracticeOrderingPauseInformation: 'OrderingPausedPracticeDTO',
    getPracticeProductionOrderOverview: 'PracticeProductionOrderOverviewDTO',
    getPracticeRequests: 'PracticeRequestDTO',
    getPracticeStaffPreferencesById: 'DoctorPreferences',
    getPracticeTrainingDate: 'DateTime',
    getPreferencesByIds: 'DoctorPreferences',
    getPreferencesByStaffMemberIds: 'DoctorPreferences',
    getProductCatalogEntry: 'ProductCatalog',
    getProductCatalogEntryWithDetails: 'ProductCatalogWithDetailsDTO',
    getProductCatalogPrice: 'ProductCatalogPrice',
    getProductionOrderById: 'ProductionOrderDTO',
    getProductionPlanForOrder: 'ProductionPlanDebuggerView',
    getQCIssuesForOrder: 'QcIssue',
    getQCItemsForOrder: 'QualityControlItemResult',
    getQcWarningsForOrder: 'QCWarningResult',
    getReasonCodeAdditionalInfoRequestsAndCounts: 'ReasonCodeAdditionalInfoRequestCount',
    getReasonCodeCategoriesAndCounts: 'ReasonCodeCategoryCount',
    getRecentReviews: 'IReviewSubmission',
    getRefabCategoriesAndCounts: 'RefabReasonCategoryCount',
    getRefabRateBySkuForPartner: 'RefabRateBySkuForPartner',
    getRegistrationURL: 'String',
    getRemovableManufacturingFiles: 'DesignOrderRemovableManufacturingFilesDTO',
    getReview: 'IReviewSubmission',
    getReviewTagCategories: 'String',
    getReviews: 'IReviewSubmission',
    getRoutingRulesForOrder: 'RoutingRulesForOrderResult',
    getSavedSearchById: 'SavedSearch',
    getSavedSearches: 'SavedSearch',
    getScanbodies: 'ScanbodyDTO',
    getScanbodiesByIds: 'ScanbodyDTO',
    getScanbodyRequestById: 'ScanbodyRequestDTO',
    getScanbodyRequests: 'ScanbodyRequestDTO',
    getScanbodyRequestsInLast30Days: 'ScanbodyRequestDTO',
    getScanbodyRequestsInLastNDays: 'ScanbodyRequestDTO',
    getScannersByPractice: 'ScannerEntity',
    getScheduledLiveCasePlanningRequestNotificationInfo: 'LiveCasePlanningRequestNotificationInfo',
    getScheduledLiveDdpReviewRequestNotificationInfo: 'LiveCasePlanningRequestNotificationInfo',
    getScheduledLiveLabTechMeetingRequestNotificationInfo: 'LiveLabTechMeetingRequestNotificationInfo',
    getSetupChampionBySalesforceID: 'SetupChampion',
    getShadeSuggestionWithPhotosByShadeCaptureId: 'ShadeSuggestionWithPhotosDto',
    getShipmentsForOrder: 'ShipmentDTO',
    getShippingAddressByPracticeID: 'ShippingAddress',
    getShortenedLink: 'String',
    getSignedDownloadUrl: 'FileDownloadUrl',
    getSignedUploadUrl: 'FileUploadUrl',
    getSkusForPartner: 'SkusForPartner',
    getSmsContentsByNotificationLogId: 'GetSmsContentsByNotificationLogIdResult',
    getStaffActivatedAtFromPreferencesId: 'String',
    getStaffForUser: 'StaffMemberWithRelations',
    getStaffMemberById: 'StaffMemberWithRelations',
    getStaffMemberForImpersonate: 'StaffMemberWithRelations',
    getStaffMemberOnboardingPreferences: 'StaffMemberOnboardingDTO',
    getStaffMembersByIds: 'StaffMemberWithRelations',
    getStaffMembersOnboardingPreferences: 'StaffMemberOnboardingDTO',
    getStageByPracticeId: 'PracticeStage',
    getStoragePathConfig: 'StoragePathConfig',
    getTicketSavedSearches: 'TicketingSavedSearch',
    getTieredPricingConfig: 'TieredPricingConfigEntityDto',
    getTrainingStartTime: 'DateTime',
    getTryInFeedbackForOrder: 'TryInFeedbackDTO',
    getUnusedCaseReadinessCases: 'CaseReadinessDTO',
    getUser: 'UserDTO',
    getUserByMobilePhoneNumber: 'UserDTO',
    getUserFromDrPrefId: 'UserDTOFragment',
    getUserReadEvents: 'UserReadEvents',
    getZendeskAgentsFromGroup: 'ZendeskAgent',
    googleJwtLogin: 'UserLoginOption',
    isAccountActivated: 'Boolean',
    isOrderEligibleForLiveDesignReview: 'Boolean',
    isOrderEligibleForLiveLabTechMeeting: 'Boolean',
    labOrderCountByStatus: 'LabOrderStatusCount',
    labOrderRefabRates: 'LabOrderRefabRates',
    labPortalAutoNestingInProgressOrdersByListView: 'LabPortalNestingInProgressOrderListItem',
    labPortalDesignOrderCounts: 'LabPortalDesignOrderListCounts',
    labPortalDesignOrdersByListView: 'LabPortalDesignOrderListItem',
    labPortalDesignReviseOrdersByListView: 'LabPortalDesignReviseOrderListItem',
    labPortalDesignSendToFloorOrdersByListView: 'LabPortalDesignSendToFloorOrderListItem',
    labPortalDesignWaitForDandyOrdersByListView: 'LabPortalDesignWaitForDandyOrderListItem',
    labPortalIntakeOrderCounts: 'LabPortalIntakeOrderListCounts',
    labPortalIntakeOrdersWithDesignByListView: 'LabPortalNewOrderListItem',
    labPortalIntakeOrdersWithReturnByListView: 'LabPortalNewOrderListItem',
    labPortalIntakeOrdersWithViewedDocsByListView: 'LabPortalNewOrderListItem',
    labPortalIntakeOrdersWithoutDesignByListView: 'LabPortalNewOrderListItem',
    labPortalNestedIntakeOrdersByListView: 'LabPortalNestedIntakeOrderListItem',
    labPortalNewAcceptedOrdersWithoutLabSlipViewByListView: 'LabPortalNewOrderListItem',
    labPortalNewNeedsManualEntryOrdersByListView: 'LabPortalNewNeedsManualEntryOrderListItem',
    labPortalNewOrderCounts: 'LabPortalNewOrderListCounts',
    labPortalNewOrdersByListView: 'LabPortalNewOrderListItem',
    labPortalNewOrdersWithDesignByListView: 'LabPortalNewOrderListItem',
    labPortalNewOrdersWithReturnByListView: 'LabPortalNewOrderListItem',
    labPortalNewOrdersWithoutDesignByListView: 'LabPortalNewOrderListItem',
    labPortalOnHoldOrderCounts: 'LabPortalOnHoldOrderListCounts',
    labPortalOnHoldOrderCountsNullable: 'LabPortalOnHoldOrderListCountsNullable',
    labPortalOnHoldOrdersByListView: 'LabPortalOnHoldOrderListItem',
    labPortalOrderDetails: 'LabPortalOrderDetails',
    labPortalOrderUpdatesByListView: 'LabPortalOrderUpdateListItem',
    labPortalOverview: 'LabPortalOverviewViewModel',
    labPortalPullFromFloorUpdatesByListView: 'LabPortalPullFromFloorUpdateListItem',
    labPortalReturnsOrderCounts: 'LabPortalReturnsOrderListCounts',
    labPortalReturnsPendingOrdersByListView: 'LabPortalReturnsPendingOrderListItem',
    labPortalShipTodayLateOrdersByListView: 'LabPortalShipTodayLateOrderListItem',
    labPortalShipTodayOrderCounts: 'LabPortalShipTodayOrderListCounts',
    labPortalShipTodayOrdersByListView: 'LabPortalShipTodayOrderListItem',
    labPortalShipTodayOvernightOrdersByListView: 'LabPortalShipTodayOvernightOrderListItem',
    labPortalSidebarCounts: 'LabPortalSidebarCounts',
    labPortalUpdatesOrderCounts: 'LabPortalUpdatesOrderListCounts',
    lab_order: 'LabOrderWithEvents',
    labtracIdForOrder: 'String',
    lastMatchingOrderByUnitType: 'lab_order',
    listAbutmentPartByImplantAndScanbody: 'AbutmentPartToImplantAndScanbodyDTO',
    listAbutmentParts: 'AbutmentPartDTO',
    listAddresses: 'PartnerDeliveryAddress',
    listAddressesForContract: 'PartnerDeliveryAddress',
    listAutomationActions: 'AutomationActionDefinition',
    listCategories: 'CategoryDTO',
    listChatMessages: 'ChatMessageDTO',
    listDesignOrderDoctorReviews: 'DesignOrderDoctorReviewDTO',
    listDesignReviewFields: 'DesignReviewFieldDTO',
    listDesignTrainingCoursesForEvaluation: 'DesignTrainingCourseForEvaluation',
    listDisplayRuleOptions: 'DisplayRuleOptions',
    listFilteredDesigners: 'ListDesignersResult',
    listInvoiceCreditsForOrganization: 'InvoiceCreditDTO',
    listInvoiceItemsForExport: 'InvoiceItemDTO',
    listInvoiceItemsForInvoice: 'InvoiceItemDTO',
    listInvoicePaymentsForExport: 'InvoicePaymentWithInvoiceIdDTO',
    listInvoicesForOrganization: 'InvoiceDTO',
    listInvoicesWithItemSummariesForActiveContract: 'InvoiceWithItemSumaryDTO',
    listInvoicesWithItemSummariesForEnterprise: 'InvoiceWithItemSumaryDTO',
    listInvoicesWithItemSummariesForOrganization: 'InvoiceWithItemSumaryDTO',
    listLabtracProductCodes: 'String',
    listManufacturerPreferences: 'ManufacturerNotificationPreferences',
    listManufacturerProfiles: 'ManufacturerProfile',
    listManufacturerTruckTimeSupportedCarriers: 'ManufacturerTruckTimeOption',
    listManufacturerTruckTimes: 'ManufacturerTruckTimeEntity',
    listMatchingDesignersForOrder: 'ListMatchingDesignersResult',
    listMyDesignTasks: 'WorkflowTask',
    listMyDesignTrainingCourses: 'MyCourses',
    listOrderAutomations: 'OrderAutomation',
    listOrderFilters: 'FilterDefinition',
    listOrderReturns: 'IOrderReturn',
    listOrgUsers: 'UserDTO',
    listOrganizationStaffBillingContacts: 'StaffBillingContact',
    listOrganizationSummaries: 'OrganizationSummary',
    listOrganizations: 'OrganizationDTO',
    listOrganizationsWithAncestors: 'OrganizationWithAncestors',
    listOrganizationsWithAncestorsSummary: 'OrganizationWithAncestorsSummary',
    listOrganizationsWithDescendants: 'OrganizationWithDescendants',
    listOrganizationsWithDescendantsSummary: 'OrganizationWithDescendantsSummary',
    listOrganizationsWithHierarchySummary: 'OrganizationWithHierarchySummary',
    listOverdueInvoices: 'InvoiceDTO',
    listPalateOrderFilters: 'FilterDefinition',
    listPartialsFulfillmentNeedsFeedbackOrders: 'LabOrderWithEvents',
    listPartialsFulfillmentNeedsReturnLabelOrders: 'LabOrderWithEvents',
    listPartnerBillingAccounts: 'IPartnerBillingWithName',
    listPartnerPreferences: 'PartnerNotificationPreferences',
    listPendingIteroOrdersForPortalNotifications: 'IteroOrderNotification',
    listPendingReviewLabOrderIds: 'String',
    listPracticeBillingContactUsers: 'BillingContactUser',
    listPracticeContractsV2ByOrganizationId: 'PracticeContract',
    listPracticeNames: 'PracticeNameAndPrimaryContactInfo',
    listPracticePatients: 'PatientDTO',
    listPracticePatientsWithSortTools: 'PatientDTO',
    listPracticeSupport: 'PracticeSupportDTO',
    listPrices: 'IPrice',
    listPricesWithPracticeCounts: 'IPriceDefinitionResult',
    listProductCatalogEntries: 'ProductCatalog',
    listProductCatalogEntriesWithDetails: 'ProductCatalogWithDetailsDTO',
    listProductCatalogPrices: 'ProductCatalogPrice',
    listQCItemConfigs: 'QCItemConfigDTO',
    listQcIssues: 'QcIssue',
    listReasonCodes: 'ReasonCodeDTO',
    listRefabReasons: 'RefabReasonDTO',
    listRefundCategories: 'RefundCategoryDTO',
    listReviewTags: 'ReviewTag',
    listSalesforcePriceNameMappings: 'String',
    listSalesforcePriceNameMappingsForLabPrices: 'String',
    listShipments: 'IShipment',
    listSurgicalGuideDrillKits: 'SurgicalGuideDrillKit',
    listThreeshapeDmes: 'ThreeshapeDmeDTO',
    listUserOrganizations: 'OrganizationDTO',
    listUserPositions: 'StaffMemberDTO',
    listUsers: 'UserDTO',
    listUsersPositions: 'StaffMemberDTO',
    listWorkflowStepDefinitions: 'WorkflowStepDefinition',
    listZeroOutModelsBulkPricingJobs: 'OrderPriceAdjustmentBulkJobStatus',
    loadOrderFilterOptions: 'ICustomFieldOption',
    manufacturerUpdatesScreenCount: 'Int',
    needsBillingSummaryEmail: 'Boolean',
    needsBillingSummaryForContract: 'OrganizationNeedsSummaryEmailDTO',
    orderEntriesByListView: 'ListViewEntry',
    orderInboxItems: 'OrderInboxItem',
    orderWorkflowTasksByListView: 'WorkflowTask',
    orderWorkflowTasksCount: 'WorkflowTaskStatusCounts',
    ordersByActiveDesignTask: 'ActiveDesignTaskListPayload',
    ordersByIds: 'LabOrderWithEvents',
    ordersByListView: 'LabOrderWithEvents',
    ordersByScanId: 'LabOrderWithEvents',
    ordersForChairsideCache: 'LabOrderWithEvents',
    ordersForLabSlipByIds: 'LabOrderForLabSlip',
    ordersMatchingAutomationFilterCount: 'Int',
    panLabelsByLabOrderIds: 'PanLabelUnion',
    parse_3oxz_3ox: 'three_shape_3ox',
    parse_3oxz_case: 'three_shape_case',
    parse_3oxz_from_filepath: 'three_shape_3oxz_data',
    parse_3oxz_from_files: 'three_shape_3oxz_data',
    partnerBillingCurrentState: 'PartnerBillingCurrentState',
    partnerPrices: 'PartnerPriceResult',
    partnerSessionForScanner: 'ScannerPartnerSession',
    passwordLogin: 'UserLoginOption',
    patientPortalPatientShare: 'PatientShare',
    patient_portal_lab_order: 'PatientPortalLabOrder',
    pendingAlignerCaseForScanExport: 'PendingAlignerCaseDTO',
    pendingReturnForOrder: 'IOrderReturn',
    pendingReviewForOrder: 'IPendingReview',
    phoneNumberPasswordLogin: 'UserLoginOption',
    practiceInboxActiveOrders: 'InboxActiveOrders',
    practiceOrderCountsOverview: 'PracticeOverviewOrderCounts',
    practiceOrderIdsByStatus: 'OnlyId',
    practiceOrdersCount: 'Int',
    practiceSearchOrderIds: 'OrderSearchPracticeSearchOrderIdsResult',
    previewCreditAndRefundOptionsForOrderCancellation: 'PreviewCreditAndRefundOptionsForOrderCancellation',
    previewPracticePendingInvoiceItems: 'PendingInvoiceItemDTO',
    previewPracticePendingInvoiceItemsForActiveContract: 'PendingInvoiceItemForOrganizationDTO',
    previewProductionPlanForLabOrder: 'ProductionPlanDebuggerView',
    previewUpdateOrderSla: 'SLAPreviewResult',
    previewUpdateRushConfiguration: 'SLAPreviewResult',
    reasonCodesForOrderItem: 'LabOrderReasonCodeOption',
    refabReasonsForOrderItems: 'LabOrderRefabReasonOption',
    refreshToken: 'String',
    resolveDefaultActionTimes: 'ActionTypeActionTimes',
    routingTargetDistributionRules: 'RoutingTargetDistributionRule',
    salesOrderGetById: 'SalesOrderDTO',
    salesOrderGetBySplitId: 'SalesOrderDTO',
    salesOrderGetIdByOrderNumber: 'SalesOrderIdOnlyDTO',
    salesOrderGetInRefabChain: 'SalesOrderDTO',
    scan_file: 'scan_export',
    scan_files: 'scan_export',
    scan_files_for_patient: 'scan_export',
    searchPracticePatients: 'PatientDTO',
    singleAddress: 'PartnerDeliveryAddress',
    slimOrderInboxItems: 'SlimOrderInboxItem',
    staffMemberForPreferences: 'MigratedStaffResult',
    tags: 'Tag',
    taskAssignmentTimeline: 'TaskAssignmentTimelineEntry',
    tatGetById: 'Tat',
    tatGetByOrderId: 'Tat',
    tatListAddOnLeadTimes: 'TatAddOnLeadTimeDto',
    tatListItemLeadTimeOverrides: 'TatItemLeadTimeOverrideDto',
    tatListItemLeadTimes: 'TatItemLeadTimeDto',
    tatMakePlan: 'TatNonNullablePlan',
    timelineItems: 'TimelineItemUnion',
    timelineV2Items: 'OrderTimelineV2Item',
    uploadedAlignerPhotoGCSPaths: 'String',
    uploadedAlignerPhotoTypes: 'AlignerCasePhotoType',
    userExistsWithMobileNumber: 'Boolean',
    validateDeliveryAddress: 'DeliveryAddressValidationResult',
    validateDesignOrderRevisionUpload: 'DesignOrderRevisionValidationResult',
    waxupRecommendationV2: 'CartRecommendationV2',
    zendeskLSRTicketForScan: 'ZendeskLSRTicketDetails',
    zendeskUser: 'ZendeskChatUser',
    acceptAutomateDesignRevision: 'Boolean',
    acknowledgeAllLabUpdates: 'Boolean',
    acknowledgeLabUpdatesForOrder: 'Boolean',
    acknowledgeManualEntryLmsOrder: 'AcknowledgeManualEntryLmsOrderResponse',
    activateOrganizationStaffMember: 'OrganizationDTO',
    activatePracticeContract: 'FullyResolvedPracticeContractDTO',
    addAbutmentPartToImplantScanbody: 'ImplantTypeDTO',
    addCRMTicketTags: 'Boolean',
    addCadSmileLibraryRevision: 'CadSmileLibraryDto',
    addEntityTag: 'TaggedEntityDTO',
    addExternalStaffMember: 'OrganizationDTO',
    addInternalStaffMember: 'OrganizationDTO',
    addLabOrderPhotos: 'lab_order',
    addLabOrderPhotosWithNotes: 'lab_order',
    addLabSlipAlert: 'lab_order',
    addLabStaffMember: 'OrganizationDTO',
    addManualQualityControlItem: 'lab_order',
    addOrderItem: 'lab_order',
    addOrderItemsV2: 'lab_order',
    addOrderToShipment: 'OrderShipmentDTO',
    addParentStaffMember: 'OrganizationDTO',
    addPhoneToDrPrefAndUser: 'Boolean',
    addPracticeStaffMember: 'OrganizationDTO',
    addPriceApplicationRule: 'IPrice',
    addReasonCodeRule: 'ReasonCodeDTO',
    addRefabReasonRule: 'RefabReasonDTO',
    addRetainerToAlignerCase: 'lab_order',
    agreeToCreditCardFees: 'IPartnerBilling',
    appendTicketAction: 'TicketDTO',
    applyInvoiceCreditToInvoice: 'InvoiceDTO',
    applyPricePreset: 'IPricePreset',
    approveAlignerFabrication: 'lab_order',
    archiveAbutmentPart: 'AbutmentPartDTO',
    archiveCadSmileLibrary: 'CadSmileLibraryDto',
    archiveOrderAutomation: 'OrderAutomation',
    archiveProductCatalogEntry: 'ProductCatalogWithDetailsDTO',
    archiveQcItemConfig: 'QCItemConfigDTO',
    archiveThreeshapeDme: 'ThreeshapeDmeDTO',
    assignFulfillmentTask: 'lab_order',
    attachAlignerPreppedScans: 'Boolean',
    attachDesignAsset: 'Boolean',
    attachDesignFiles: 'lab_order',
    attachExternalAlignerFulfillment: 'lab_order',
    attachQcPhotos: 'lab_order',
    attachScanStlFiles: 'scan_export',
    attachTreatmentPlanRequest: 'lab_order',
    batchOrderShipments: 'BatchOrderShipmentsResult',
    batchOrderShipmentsAndRecordPackingCompleted: 'BatchOrderShipmentsAndRecordPackingCompletedResult',
    blockStacks: 'Boolean',
    bulkBackfillDesignOrderDoctorReviews: 'Boolean',
    bulkCreateCreditsWorkflow: 'BulkCreateCreditsWorkflowResult',
    bulkCreateDesignOrders: 'Boolean',
    bulkCreateDesignStaffTeams: 'DesignStaffTeamDTO',
    bulkCreateScanbodyRequests: 'ScanbodyRequestDTO',
    bulkCreateUsers: 'BulkCreateUserResult',
    bulkDeleteAutomations: 'Boolean',
    bulkDeleteDesignStaffTeams: 'Boolean',
    bulkDownloadDesignStaffRoster: 'BulkDownloadResult',
    bulkOverrideLabUnitPrices: 'LabBulkOverrideOrderUnitPriceResult',
    bulkRegisterInvoiceItems: 'BulkRegisterInvoiceItemResult',
    bulkRemoveStaffMembers: 'OrganizationDTO',
    bulkRestoreAutomations: 'Boolean',
    bulkTagEntities: 'Boolean',
    bulkToggleWillBeChargedCCFee: 'BulkToggleWillBeChargedCCFeeResult',
    bulkUpdateAbutmentPartScrewSku: 'Boolean',
    bulkUpdateDesignStaffRoster: 'BulkUpdateResult',
    bulkUpdateOrderPrices: 'OrderPriceDTO',
    bulkUpdateOrganizationIsFakeStatus: 'Boolean',
    bulkUpdatePartnerBillingConfigFlag: 'IPartnerBilling',
    bulkUpsertLabPriceLabPrices: 'Boolean',
    bulkUpsertOrganizationPrices: 'Boolean',
    bulkUpsertPrices: 'BulkPriceUpsertRes',
    cancelAndResubmit: 'lab_order',
    cancelDesignQaEvaluations: 'Boolean',
    cancelLiveCasePlanningRequest: 'Boolean',
    cancelLiveDdpReviewRequest: 'Boolean',
    cancelLiveDdpReviewRequestByEventId: 'Boolean',
    cancelLiveLabTechMeetingRequest: 'Boolean',
    cancelManufacturingOrder: 'ManufacturingOrderDTO',
    cancelOrderAutomateTask: 'Boolean',
    cancelOrderReturn: 'IOrderReturn',
    cancelShipment: 'Boolean',
    carestreamPreUpload: 'Boolean',
    changeDeliveryAddress: 'lab_order',
    changeDesignSoftware: 'lab_order',
    changeLabOrderManufacturer: 'lab_order',
    changeManufacturerBulk: 'ManufacturerBulkMutationResult',
    changePreferenceStaffMember: 'DoctorPreferences',
    changePriceName: 'IPrice',
    claimTicketAction: 'TicketDTO',
    closeTicket: 'TicketDTO',
    closeTicketAction: 'TicketDTO',
    collectNotificationPreferences: 'DoctorPreferences',
    collectPartialPayment: 'InvoicePaymentDTO',
    completeDesignPrepTask: 'lab_order',
    completeDesignReviewTask: 'lab_order',
    completeExternalDesignVerificationTask: 'lab_order',
    completeInternalDesignTask: 'lab_order',
    completeModelDesignTask: 'lab_order',
    completeOnboarding: 'PracticeOnboardingDTO',
    completeQualityControlItem: 'lab_order',
    completeTreatmentPlanConversion: 'lab_order',
    configureDesignReview2Command: 'lab_order',
    configureLabPriceLabPrices: 'LabPriceDTO',
    configureLabPricePartnerPrices: 'LabPriceDTO',
    configureOrderDesignReview: 'lab_order',
    configureOrderDesignerType: 'lab_order',
    configureOrderWaxup: 'lab_order',
    confirmOrderReturnReceived: 'IOrderReturn',
    continueDenturesFulfillmentWaxRim: 'lab_order',
    convertPracticeToParent: 'Boolean',
    createAbutmentPart: 'AbutmentPartDTO',
    createActionCapability: 'ActionCapabilityDTO',
    createActionCategory: 'ActionCategoryDTO',
    createActionType: 'ActionTypeDTO',
    createAndDeleteFocusAreas: 'DesignStaffFocusAreaDTO',
    createAttributedCredits: 'InvoiceCreditDTO',
    createBiasRule: 'BiasRule',
    createBillingCreditCategory: 'BillingCreditCategory',
    createCRMChatMessage: 'CRMChatMessage',
    createCRMTicket: 'CRMTicket',
    createCRMTicketComment: 'CRMTicket',
    createCRMUser: 'CRMUser',
    createCadSmileLibrary: 'CadSmileLibraryDto',
    createCadSmileLibraryWithInitialRevision: 'CadSmileLibraryDto',
    createCapacityRule: 'CapacityRule',
    createCapacityStation: 'CapacityStationDTO',
    createCapacityStationRule: 'CapacityStationRuleDTO',
    createCaseReadiness: 'CaseReadinessDTO',
    createCategory: 'CategoryDTO',
    createChairsideReviewSubmission: 'IReviewSubmission',
    createChatMessage: 'ChatMessageDTO',
    createCompletedScanningCase: 'CompletedScanningCaseDTO',
    createCrownProductCatalogEntry: 'ProductCatalogWithDetailsDTO',
    createDeliveryAddress: 'PartnerDeliveryAddress',
    createDesignQaEvaluation: 'DesignQaEvaluationDTO',
    createDesignReviewField: 'DesignReviewFieldDTO',
    createDesignRule: 'DesignRule',
    createDesignStaffTeam: 'DesignStaffTeamDTO',
    createDoctorPreferences: 'DoctorPreferences',
    createDoctorRequest: 'DoctorRequestDTO',
    createEmptyVariantFeedback: 'VariantFeedbackDTO',
    createEnableCcFeeForAllWorkflow: 'EnableCcFeeForAllWorkflowResult',
    createExternalOrg: 'ExternalOrgCreatedRes',
    createFabricationBulk: 'CreateFabricationResult',
    createFabricationBulkInternal: 'Boolean',
    createFulfillment: 'lab_order',
    createGoldilocksControlGroupMetadata: 'CreateOrderMetadataOutput',
    createImplantType: 'ImplantTypeDTO',
    createInvoice: 'InvoiceDTO',
    createInvoiceCredit: 'InvoiceCreditDTO',
    createLabCaseCompletion: 'LabCaseCompletionDTO',
    createLabCaseLedgerEntry: 'LabCaseLedgerDTO',
    createLabMetalCost: 'LabMetalCostDTO',
    createLabPrice: 'LabPriceDTO',
    createLabPriceLedgerEntry: 'LabPriceLedgerDTO',
    createLabPriceLedgerPartnerEntry: 'LabPriceLedgerDTO',
    createLabsManufacturer: 'ManufacturerCreatedRes',
    createLiveCasePlanningRequest: 'LiveCasePlanningRequestDto',
    createLiveDdpReviewRequest: 'LiveDdpReviewRequestDto',
    createLiveLabTechMeetingRequest: 'LiveLabTechMeetingRequestDto',
    createManualLabOrderIntegrationAttempt: 'CreateLabOrderIntegrationAttemptResponse',
    createManufacturerTruckTime: 'ManufacturerTruckTimeEntity',
    createManufacturingBookings: 'ManufacturingBookingDto',
    createMetalMaterial: 'MetalMaterialDTO',
    createNonDoctorPracticeStaff: 'NonDoctorPracticeStaffDTO',
    createOrFindChairsidePatient: 'PatientDTO',
    createOrderAutomation: 'OrderAutomation',
    createOrderHold: 'lab_order',
    createOrderItemVariantCharge: 'PracticeBillingRecurringItemsDTO',
    createOrderPriceBackfillWorkflow: 'CreateOrderPriceBackfillWorkflowResult',
    createOrderPriceEntry: 'OrderPriceDTO',
    createOrganization: 'OrganizationDTO',
    createOrganizationPriceConfig: 'OrganizationPriceConfigDTO',
    createParentOrg: 'Boolean',
    createPartner: 'Boolean',
    createPartnerBilling: 'IPartnerBilling',
    createPassword: 'CreatePasswordResult',
    createPatient: 'PatientDTO',
    createPaymentMethod: 'StripePaymentId',
    createPendingAlignerCase: 'PendingAlignerCaseDTO',
    createPracticeCaseBulk: 'IPracticeCase',
    createPracticeRequestMarketingMaterials: 'PracticeRequestDTO',
    createPracticeRequestScheduleTraining: 'PracticeRequestDTO',
    createPracticeSupport: 'PracticeSupportDTO',
    createPrice: 'IPrice',
    createPricePreset: 'IPricePreset',
    createProductStationUnitMapping: 'ProductStationUnitMappingDTO',
    createQcIssue: 'QcIssue',
    createQcItemConfig: 'QCItemConfigDTO',
    createReasonCode: 'ReasonCodeDTO',
    createRebuildAggregateByIdsWorkflow: 'CreateRebuildAggregateWorkflowResult',
    createRebuildAggregateWorkflow: 'CreateRebuildAggregateWorkflowResult',
    createRefabReason: 'RefabReasonDTO',
    createRefundCategory: 'RefundCategoryDTO',
    createReturnForRefabrication: 'IOrderReturn',
    createReturnForTryIn: 'IOrderReturn',
    createReviewSubmissionV3: 'IReviewSubmission',
    createReviewTag: 'ReviewTag',
    createRoutingTargetDistribution: 'RoutingTargetDistributionRule',
    createSavedInboxSearch: 'SavedSearch',
    createSavedOrderSearch: 'SavedSearch',
    createSavedRoutingSearch: 'SavedSearch',
    createScanExportPreUpload: 'CreateScanPreUploadRes',
    createScanExportPreUploadScanner: 'CreateScanPreUploadRes',
    createScanExportPreUploadWithRaw: 'CreateScanPreUploadRes',
    createScanExportPreUploadWithRawScanner: 'CreateScanPreUploadRes',
    createScanbody: 'ScanbodyDTO',
    createScanbodyRequest: 'ScanbodyRequestDTO',
    createScanner: 'ScannerEntity',
    createShadeSuggestionWithPhotos: 'String',
    createShippingLabel: 'String',
    createTag: 'Tag',
    createThreeshapeDme: 'ThreeshapeDmeDTO',
    createTicket: 'TicketDTO',
    createTicketSavedSearch: 'TicketingSavedSearch',
    createTraining: 'Training',
    createTrainingIntakeResponse: 'String',
    createTryInFeedback: 'TryInFeedbackDTO',
    createUser: 'UserDTO',
    createVideoDdpReviewRequest: 'VideoDdpReviewRequestDTO',
    createWorkflowStepDefinition: 'WorkflowStepDefinition',
    deleteChatMessage: 'ChatMessageDTO',
    deleteDoctorPreferences: 'DoctorPreferences',
    deleteImplantType: 'ImplantTypeDTO',
    deleteInvoiceCredit: 'InvoiceCreditDTO',
    deleteManufacturerTruckTime: 'ManufacturerTruckTimeEntity',
    deleteManufacturingTechnicians: 'DeleteTechniciansResponseDto',
    deleteMetalMaterial: 'MetalMaterialDTO',
    deleteNonDoctorPracticeStaff: 'NonDoctorPracticeStaffDTO',
    deleteOrderAutomation: 'OrderAutomation',
    deleteOrderItem: 'lab_order',
    deleteOrderPriceEntry: 'OrderPriceDTO',
    deleteOrganizationPriceConfig: 'OrganizationPriceConfigDTO',
    deletePartnerPriceConfig: 'IPrice',
    deletePracticeSupport: 'PracticeSupportDTO',
    deletePrice: 'IPrice',
    deletePricePreset: 'IPricePreset',
    deleteProductStationUnitMapping: 'ProductStationUnitMappingDTO',
    deleteReasonCodeRule: 'ReasonCodeDTO',
    deleteRefabReasonRule: 'RefabReasonDTO',
    deleteRegisteredInvoiceItem: 'PracticeBillingRecurringItemsDTO',
    deleteSavedSearch: 'SavedSearch',
    deleteScanExport: 'Boolean',
    deleteScanbody: 'ScanbodyDTO',
    deleteScanbodyRequest: 'ScanbodyRequestDTO',
    deleteTag: 'Tag',
    deleteTicketSavedSearch: 'TicketingSavedSearch',
    dev_associate_treatment_plan_with_case: 'Boolean',
    disableBiasRule: 'BiasRule',
    disableCapacityRule: 'CapacityRule',
    disableCapacityStationRule: 'CapacityStationRuleDTO',
    disableDesignRule: 'DesignRule',
    disableRoutingTargetDistributionRule: 'RoutingTargetDistributionRule',
    editAccountManagementContacts: 'IPartnerBilling',
    editAlignerCase: 'lab_order',
    editBillingContacts: 'IPartnerBilling',
    editDeliveryAddress: 'PartnerDeliveryAddress',
    editDoctorNote: 'lab_order',
    editQcItemConfig: 'QCItemConfigDTO',
    editRefabrication: 'lab_order',
    editRefabricationReasons: 'lab_order',
    editSavedInboxSearch: 'SavedSearch',
    editSavedOrderSearch: 'SavedSearch',
    editSavedRoutingSearch: 'SavedSearch',
    editTag: 'Tag',
    editTicketActionNote: 'TicketDTO',
    editTicketSavedSearch: 'TicketingSavedSearch',
    emailReturnLabel: 'Boolean',
    emergencyOnlyHardDeleteContract: 'Boolean',
    enableBiasRule: 'BiasRule',
    enableCapacityRule: 'CapacityRule',
    enableDesignRule: 'DesignRule',
    enableRoutingTargetDistributionRule: 'RoutingTargetDistributionRule',
    enrollInDesignTrainingCourse: 'EnrolledCourse',
    ensureStackCodesForLabOrders: 'EnsureStackCodesForLabOrdersResult',
    executeTemporalWorkflow: 'CreateRebuildAggregateWorkflowResult',
    externalUserCancelOrder: 'lab_order',
    favoriteSavedSearch: 'SavedSearch',
    favoriteTicketSavedSearch: 'TicketingSavedSearch',
    finalizeTreatmentPlanRequest: 'lab_order',
    flagOrderForReview: 'lab_order',
    forceReturnRefabConversion: 'String',
    fulfillVideoDdpReviewRequest: 'Boolean',
    generateEmptyDesignFile: 'String',
    generateSoftsmileWebviewerLink: 'String',
    generate_softsmile_vision_link: 'String',
    getDownloadUrl: 'String',
    handleFcmCheck: 'FcmCheckResult',
    handleFcmCheckByCaseInfo: 'FcmCheckResult',
    ignoreQualityControlItem: 'lab_order',
    invalidateInternalRemake: 'Boolean',
    issueAttributedRefunds: 'InvoiceDTO',
    labOrderQcStationFail: 'lab_order',
    labOrderQcStationPass: 'lab_order',
    linkOrderToRefabrication: 'lab_order',
    linkOrganizationExternalAccount: 'OrganizationDTO',
    linkScanbodyRequestFulfillment: 'ScanbodyRequestDTO',
    logThreeshapePluginOperation: 'Boolean',
    manuallyAttachInjectionMoldFiles: 'Boolean',
    markDelivered: 'lab_order',
    markEventUnread: 'UserReadEvents',
    markEventsRead: 'UserReadEvents',
    markMfgUnitsAutoNestingCompleteWithDetailedResponse: 'MarkMfgUnitsAutoNestingCompletedResult',
    markNeedsRefabrication: 'lab_order',
    markOrdersIntakeCompletedByLabOrderIds: 'Int',
    markReturnReceivedBulk: 'ManufacturerBulkMutationResult',
    markShipped: 'lab_order',
    mergeAndStitchDesignOrders: 'Boolean',
    migratePreferencesToStaff: 'MigratedStaffResult',
    mobileUploadLoaded: 'Boolean',
    modifyCrownProductCatalogEntry: 'ProductCatalogWithDetailsDTO',
    modifyOrderSla: 'lab_order',
    nonProductionAutoSubmitDesign: 'Boolean',
    notifyQCOrderError: 'Boolean',
    offboardDesignStaff: 'DesignStaffDTO',
    oneTimeCodeLoginV2: 'OneTimeCodeLoginResult',
    oneTimeCodeVerification: 'Boolean',
    openPendingAlignerCaseEstimateModal: 'PendingAlignerCaseDTO',
    opsCancelOrder: 'lab_order',
    optOutProductionOrder: 'lab_order',
    optPracticeInToCreditCardSurcharge: 'PracticeBillingRecurringItemsDTO',
    optPracticeOutOfCreditCardSurcharge: 'PracticeBillingRecurringItemsDTO',
    overrideDentistAmountDue: 'lab_order',
    overrideLabCaseUnitPrice: 'LabCaseLedgerDTO',
    overrideNonProdManufacturer: 'lab_order',
    overridePendingAlignerPatientPrice: 'PendingAlignerCaseDTO',
    patient_portal_link: 'String',
    payContractInvoicesWithPaymentSource: 'InvoicePaymentDTO',
    payInvoiceWithConfig: 'InvoicePaymentDTO',
    payInvoiceWithManualStripePayment: 'InvoicePaymentDTO',
    payInvoiceWithPaymentSource: 'InvoicePaymentDTO',
    placeDenturesFulfillmentCore: 'lab_order',
    placeDenturesFulfillmentWaxRim: 'lab_order',
    placeLabOrderFromTryIn: 'lab_order',
    placeOrder: 'lab_order',
    postCreatePracticeOnboarding: 'PracticeOnboardingDTO',
    practiceRespondToDoctorRequest: 'DoctorRequestDTO',
    practiceRespondToRejectedScan: 'lab_order',
    preemptDesignTaskTimeout: 'DesignStaffDTO',
    printableLabSlipViewed: 'lab_order',
    process3oxzFile: 'scan_export_processed',
    process3oxzFileScanner: 'scan_export_processed',
    processThreeshapeScanNotifications: 'ProcessScanNotificationsRes',
    promoteDesignRevisionToLatest: 'Boolean',
    queueManufacturingFileCleanUp: 'Boolean',
    queueManufacturingFileProcessing: 'Boolean',
    queueZeroOutModelsBulkPricingJob: 'OrderPriceAdjustmentBulkJobStatus',
    reOnboardDesignStaff: 'DesignStaffDTO',
    reactivateDoctorPreferences: 'DoctorPreferences',
    reactivateOrganizationStaffMember: 'OrganizationDTO',
    recomputeOrder: 'lab_order',
    recordActivationFee: 'PracticeBillingRecurringItemsDTO',
    recordDesignDownload: 'lab_order',
    recordDesignDownloadedManufacturerBulk: 'ManufacturerBulkMutationResult',
    recordInternalRemake: 'Boolean',
    recordLabSlipsViewedByLabOrderIds: 'Int',
    recordPackingCompletedForManufacturingOrders: 'Int',
    recordPackingSheetViewed: 'lab_order',
    recordPartnerActivationDate: 'IPartnerBilling',
    recordShippingDeptScanAndFetchBatchCandidates: 'BatchShipCandidatesSearchResult',
    recordShippingDeptScanAndFetchBatchDecision: 'BatchShipDecisionResult',
    recordUpgradeFee: 'PracticeBillingRecurringItemsDTO',
    refreshImagesForProcessedScan: 'scan_export',
    refreshShipmentTracking: 'IShipment',
    refreshShippingStatus: 'update_delivery_statuses_result',
    refundInvoicePayment: 'InvoicePaymentDTO',
    registerActivationFeeCharge: 'PracticeBillingRecurringItemsDTO',
    registerInvoiceItem: 'PracticeBillingRecurringItemsDTO',
    rejectPendingAlignerCase: 'PendingAlignerCaseDTO',
    rejectTreatmentPlan: 'lab_order',
    releaseTicketAction: 'TicketDTO',
    removeAbutmentPartToImplantScanbody: 'ImplantTypeDTO',
    removeEntityTag: 'TaggedEntityDTO',
    removeFulfillmentShipping: 'lab_order',
    removeInvoiceItem: 'InvoiceDTO',
    removeLabSlipAlerts: 'lab_order',
    removeOrderFromShipment: 'Boolean',
    removeOrderHold: 'lab_order',
    removeOrganizationStaffMember: 'OrganizationDTO',
    removePaymentMethod: 'StripePaymentId',
    removePreferenceStaffMember: 'DoctorPreferences',
    removePriceApplicationRule: 'IPrice',
    removeScannerOwner: 'ScannerEntity',
    removeSendersFromPref: 'Boolean',
    removeUsagePaymentSplitConfig: 'IPartnerBilling',
    replaceRejectedScan: 'lab_order',
    replaceRemovableManufacturingFiles: 'DesignOrderDTO',
    replaceThreeoxz: 'lab_order',
    reprocessThreeshapeDmes: 'Boolean',
    requestOrderRefinement: 'lab_order',
    requestPrecheckoutScanReview: 'RequestPrecheckoutScanReviewRes',
    rerouteLabOrder: 'lab_order',
    rerouteOrders: 'RerouteLabOrderResult',
    rerunDesignConversion: 'Boolean',
    resendOrdersToAutomate: 'ResendOrdersToAutomateResult',
    resetManufacturingBookings: 'ResultDto',
    resolveOrderReviewFlag: 'lab_order',
    resolveOrderScanRejection: 'lab_order',
    restoreImplantType: 'ImplantTypeDTO',
    restoreManufacturerTruckTime: 'ManufacturerTruckTimeEntity',
    restoreScanbody: 'ScanbodyDTO',
    runManufacturingBookingStartSyncWorkflow: 'RunWorkflowDto',
    runManufacturingBookingSyncWorkflow: 'RunWorkflowDto',
    runRescheduleAndReassign: 'ProductionPlanMutationResult',
    salesOrderAddItems: 'SalesOrderMutationResultDTO',
    salesOrderChangeDeliveryAddress: 'SalesOrderMutationResultDTO',
    salesOrderChangeDoctorPreferences: 'SalesOrderMutationResultDTO',
    salesOrderDeleteItem: 'SalesOrderMutationResultDTO',
    salesOrderUpdateItems: 'SalesOrderMutationResultDTO',
    salesforceUserSync: 'Boolean',
    saveDoctorLabSlipAlerts: 'DoctorPreferences',
    saveLabSlipAlerts: 'lab_order',
    schedule3oxzToStlConversion: 'Boolean',
    sendAttachPhotosUploadLink: 'Boolean',
    sendCheckoutImagesUploadLink: 'Boolean',
    sendDoctorPrefsUpdatedNotification: 'Boolean',
    sendInviteEmail: 'Boolean',
    sendInvoiceSummaryEmail: 'BillingEmailResult',
    sendMobileAppLinks: 'Boolean',
    sendOneTimeCode: 'String',
    sendOrderToAutomate: 'Boolean',
    sendOrderToFloor: 'lab_order',
    sendOrdersToFloorBulk: 'lab_order',
    sendRefabFilesUploadLink: 'Boolean',
    sendResetPasswordEmail: 'Boolean',
    sendScanbodySurgicalReportUploadLink: 'Boolean',
    setAlignerManufacturerTreatmentPlan: 'lab_order',
    setCbctScanUrl: 'lab_order',
    setChatMessageVisibility: 'ChatMessageDTO',
    setDefaultPaymentMethod: 'StripePaymentId',
    setDesignStaffPrepCapabilities: 'DesignStaffDTO',
    setDoctorNotificationPrefs: 'DoctorPreferences',
    setDoctorPreferences: 'DoctorPreferences',
    setDoctorPreferencesId: 'lab_order',
    setDoctorPrefsForwarding: 'DoctorPreferences',
    setDoctorProfilePhoto: 'DoctorPreferences',
    setManufacturerNotificationPreferences: 'ManufacturerNotificationPreferences',
    setNonDoctorPracticeStaff: 'NonDoctorPracticeStaffDTO',
    setOrderAutomationFolder: 'OrderAutomation',
    setPartnerNotificationPreferences: 'PartnerNotificationPreferences',
    setPartnerScanners: 'ScannerResponse',
    setPracticeContractV2EndDate: 'FullyResolvedPracticeContractDTO',
    setPracticeScanStart: 'PracticeOnboardingDTO',
    setPreferredManufacturers: 'DoctorPreferences',
    setPriceSalesforceMapping: 'IPrice',
    setReceiversOnPref: 'Boolean',
    setSalesforcePriceMapping: 'LabPriceDTO',
    setScannerOwner: 'ScannerEntity',
    setSingleClinicalPreference: 'DoctorPreferences',
    setSmileStyle: 'lab_order',
    setTreatmentPlanRequestUrl: 'lab_order',
    setUsagePaymentSplitConfigDoctor: 'IPartnerBilling',
    setUsagePaymentSplitConfigLocation: 'IPartnerBilling',
    setUsagePaymentSplitConfigPercent: 'IPartnerBilling',
    setWillBeChargedCcFee: 'IPartnerBilling',
    sharePatientScan: 'String',
    shareScanRejectionWithPractice: 'lab_order',
    skipCase: 'Boolean',
    skipManufacturingBookings: 'ResultDto',
    skipMillingAutoNestingForAllOrders: 'Boolean',
    skipMillingAutoNestingForOrder: 'Boolean',
    skipOnboarding: 'PracticeOnboardingDTO',
    skipOnboardingV2: 'Boolean',
    startDesignEditorSession: 'String',
    startFulfillmentTask: 'lab_order',
    startOnboarding: 'PracticeOnboardingDTO',
    startOrderIntegrationManualV2: 'StartOrderIntegrationManualV2Result',
    startPrepInBrowser: 'String',
    submitAutomateReview: 'DesignOrderDTO',
    submitAutomateReviewForTraining: 'Boolean',
    submitDesign: 'DesignOrderDesignRevisionDTO',
    submitDesignEditor: 'Boolean',
    submitDesignEditorForTraining: 'Boolean',
    submitDesignQaEvaluation: 'DesignQaEvaluationDTO',
    submitFinishingInBrowser: 'Boolean',
    submitFinishingInBrowserForTraining: 'Boolean',
    submitOrderScanReview: 'lab_order',
    submitPendingAlignerCaseEstimate: 'PendingAlignerCaseDTO',
    submitPracticeFeedback: 'Boolean',
    submitPrepInBrowser: 'Boolean',
    submitPrepInBrowserForTraining: 'Boolean',
    submitQcFlag: 'lab_order',
    submitVariantFeedback: 'VariantFeedbackDTO',
    submitWaxupReview: 'lab_order',
    syncOrderPrices: 'lab_order',
    syncPricePresetsToSalesforce: 'Boolean',
    tagEntity: 'Tag',
    tatMigrate: 'Tat',
    tatMigrateWorkflow: 'String',
    tatSync: 'Tat',
    tatUpsertAddOnLeadTime: 'TatAddOnLeadTimeDto',
    tatUpsertItemLeadTime: 'TatItemLeadTimeDto',
    tatUpsertItemLeadTimeOverride: 'TatItemLeadTimeOverrideDto',
    testInternalLabtracIntegrationV2: 'LabtracIntegrationV2TestResult',
    testOrderAutomation: 'TestOrderAutomationResult',
    toggleArchiveCategory: 'CategoryDTO',
    toggleDeliveryAddressDeleted: 'PartnerDeliveryAddress',
    toggleLabCaseLedgerEntryReviewed: 'LabCaseLedgerDTO',
    toggleLabLedgerEntryReviewed: 'LabPriceLedgerDTO',
    toggleLabPriceDeletion: 'LabPriceDTO',
    transitionOnboardingStep: 'Boolean',
    transitionStep: 'PracticeOnboardingDTO',
    unarchiveAbutmentPart: 'AbutmentPartDTO',
    unarchiveSmileLibrary: 'CadSmileLibraryDto',
    unarchiveThreeshapeDme: 'ThreeshapeDmeDTO',
    unblockStacks: 'Boolean',
    undeleteTag: 'Tag',
    undoLinkOrderToRefabrication: 'lab_order',
    unfavoriteSavedSearch: 'SavedSearch',
    unfavoriteTicketSavedSearch: 'TicketingSavedSearch',
    unlinkOrganizationExternalAccount: 'OrganizationDTO',
    untagEntity: 'Tag',
    updateAbutmentPart: 'AbutmentPartDTO',
    updateAbutmentPartToImplantScanbody: 'ImplantTypeDTO',
    updateActionCapability: 'ActionCapabilityDTO',
    updateActionCategory: 'ActionCategoryDTO',
    updateActionType: 'ActionTypeDTO',
    updateAndCreateOrderPrices: 'OrderPriceEntityDTO',
    updateBiasRule: 'BiasRule',
    updateBillingCreditCategory: 'BillingCreditCategory',
    updateCRMChatConversation: 'CRMChatConversation',
    updateCRMTicket: 'Boolean',
    updateCapacityRule: 'CapacityRule',
    updateCapacityStation: 'CapacityStationDTO',
    updateCapacityStationPool: 'CapacityStationPoolDTO',
    updateCapacityStationRule: 'CapacityStationRuleDTO',
    updateCategory: 'CategoryDTO',
    updateCurrentRetainer: 'lab_order',
    updateDesignReviewField: 'DesignReviewFieldDTO',
    updateDesignRule: 'DesignRule',
    updateDesignStaffRosterInfo: 'DesignStaffDTO',
    updateDesignStaffStatus: 'DesignStaffDTO',
    updateExternalStaffMemberRoles: 'OrganizationDTO',
    updateImplantType: 'ImplantTypeDTO',
    updateInternalStaffMemberRoles: 'OrganizationDTO',
    updateInvoiceCredit: 'InvoiceCreditDTO',
    updateIteroSettingsOfCurrentUser: 'IteroUserSettings',
    updateLabCaseLedgerEntry: 'LabCaseLedgerDTO',
    updateLabOrderLockStatus: 'Boolean',
    updateLabOrderSplits: 'LabOrderUpdateSplitsResult',
    updateLabPrice: 'LabPriceDTO',
    updateLabStaffMemberRoles: 'OrganizationDTO',
    updateLiveLabTechMeetingRequestNotes: 'Boolean',
    updateManufacturerTruckTime: 'ManufacturerTruckTimeEntity',
    updateOrCreateIteroCompany: 'IteroCompanyEntity',
    updateOrCreateIteroUser: 'IteroUserEntity',
    updateOrderAutomation: 'OrderAutomation',
    updateOrderClinicalSupportNotes: 'lab_order',
    updateOrderHold: 'lab_order',
    updateOrderItemPennyweightsV2: 'lab_order',
    updateOrderItemsV2: 'lab_order',
    updateOrderItemsV2Practice: 'lab_order',
    updateOrderPriceEntry: 'OrderPriceDTO',
    updateOrganizationName: 'OrganizationDTO',
    updateOrganizationParentId: 'OrganizationDTO',
    updateOrganizationPhoneNumber: 'OrganizationDTO',
    updateOrganizationPriceConfig: 'OrganizationPriceConfigDTO',
    updateOrganizationSettings: 'OrganizationDTO',
    updateOrganizationStaffMemberZendeskId: 'OrganizationDTO',
    updateOrganizationType: 'OrganizationDTO',
    updateParentStaffMemberRoles: 'OrganizationDTO',
    updatePartialDentureMetadata: 'lab_order',
    updatePaymentAmount: 'InvoicePaymentDTO',
    updatePracticeOrderingPauseReasons: 'OrderingPausedPracticeDTO',
    updatePracticeSettings: 'OrganizationDTO',
    updatePracticeStaffMemberRoles: 'OrganizationDTO',
    updatePracticeSupport: 'PracticeSupportDTO',
    updatePricePreset: 'IPricePreset',
    updatePrimaryBillingContactUser: 'IPartnerBilling',
    updatePrimaryBillingContactUserId: 'IPartnerBilling',
    updateProductStationUnitMappingUnits: 'ProductStationUnitMappingDTO',
    updateQcIssue: 'QcIssue',
    updateReasonCode: 'ReasonCodeDTO',
    updateRefabReason: 'RefabReasonDTO',
    updateRefundCategory: 'RefundCategoryDTO',
    updateRegisteredInvoiceItem: 'PracticeBillingRecurringItemsDTO',
    updateReviewSubmissionItemTags: 'IReviewSubmission',
    updateReviewTag: 'ReviewTag',
    updateRoutingTargetDistributionRule: 'RoutingTargetDistributionRule',
    updateRushConfiguration: 'SLAPreviewResult',
    updateScanbody: 'ScanbodyDTO',
    updateScanbodyRequest: 'ScanbodyRequestDTO',
    updateStaffMemberOnboardingPreferences: 'StaffMemberOnboardingDTO',
    updateStaffMemberOnboardingPreferencesById: 'StaffMemberOnboardingDTO',
    updateStripeLinking: 'IPartnerBilling',
    updateThreeshapeDmeFilePath: 'ThreeshapeDmeDTO',
    updateUser: 'UserDTO',
    updateUserLoginPhone: 'UserDTO',
    updateUserPassword: 'UserDTO',
    updateWorkflowStepDefinition: 'WorkflowStepDefinition',
    upsertDesignStaff: 'DesignStaffDTO',
    upsertManufacturerProfile: 'ManufacturerProfile',
    upsertMetalCostsBulk: 'LabMetalCostDTO',
    upsertPartnerPriceConfig: 'IPrice',
    verifyLoginPhoneNumber: 'Boolean',
    voidInvoice: 'InvoiceDTO',
    withdrawFromDesignTrainingCourse: 'Boolean',
};