import { isFullDesignCourse } from '../DesignTraining.utils';
import { ButtonWithProgress } from './ButtonWithProgress';
import { useApolloClient } from '@apollo/client';
import type { DesignTrainingOrderFragment } from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import { useOrder } from '@orthly/graphql-react';
import type { LabsGqlDesignEditorPayload } from '@orthly/graphql-schema';
import { Button, type ButtonProps } from '@orthly/ui-primitives';
import { useDesignOrderRevision, DesignEditorRoot } from '@orthly/veneer';
import React from 'react';

const SubmitAutomateReviewForTrainingMutation = graphql(`
    mutation SubmitAutomateReviewForTraining($command: SubmitAutomateReviewCommand!, $trainingDesignId: ID!) {
        submitAutomateReviewForTraining(command: $command, trainingDesignId: $trainingDesignId)
    }
`);

const SubmitDesignEditorForTrainingMutation = graphql(`
    mutation SubmitDesignEditorForTraining($payload: DesignEditorPayload!, $trainingDesignId: ID!) {
        submitDesignEditorForTraining(payload: $payload, trainingDesignId: $trainingDesignId)
    }
`);

export function useSubmitCallbacks(orderId: string, trainingDesignId: string) {
    const client = useApolloClient();

    const submitReviewDecision = async (args: { approved: boolean; reviewDurationMs: number }) => {
        await client.mutate({
            mutation: SubmitAutomateReviewForTrainingMutation,
            variables: {
                command: {
                    order_id: orderId,
                    approved: args.approved,
                    review_duration_ms: args.reviewDurationMs,
                },
                trainingDesignId,
            },
        });
    };

    const submitDesign = async (args: { payload: LabsGqlDesignEditorPayload }) => {
        await client.mutate({
            mutation: SubmitDesignEditorForTrainingMutation,
            variables: { payload: args.payload, trainingDesignId },
        });
    };

    return {
        submitDesign,
        submitReviewDecision,
    };
}

export const DesignTrainingStartDesignEditorButton: React.VFC<{
    trainingOrder: DesignTrainingOrderFragment;
    onComplete: () => void;
    children?: React.ReactNode;
    prefetch?: boolean;
    ButtonComponent?: React.ComponentType<ButtonProps>;
    buttonVariant?: ButtonProps['variant'];
}> = props => {
    const {
        trainingOrder,
        onComplete,
        children,
        prefetch = false,
        ButtonComponent = Button,
        buttonVariant = 'primary',
    } = props;

    if (!trainingOrder.designRevisionId) {
        const message = 'Design revision ID is required for a Design Editor training order.';
        console.error(message, trainingOrder);
        throw new Error(message);
    }

    const [open, setOpen] = React.useState<boolean>(false);
    const skip = !prefetch && !open;

    const { order: labOrderDataRaw } = useOrder(trainingOrder.orderId, { skip });
    const labOrderData = labOrderDataRaw && {
        ...labOrderDataRaw,
        fulfillment_workflow: { ...labOrderDataRaw.fulfillment_workflow, active_task: null },
        patient: { ...labOrderDataRaw.patient, first_name: `Training #${trainingOrder.orderNumber}`, last_name: '' },
    };

    const { data: designRevisionData } = useDesignOrderRevision(trainingOrder.designRevisionId, { skip });
    const design = designRevisionData?.getDesignOrderDesignRevisionById;

    const submitCallbacks = useSubmitCallbacks(trainingOrder.orderId, trainingOrder.id);

    return (
        <>
            <ButtonWithProgress
                ButtonComponent={ButtonComponent}
                variant={buttonVariant}
                loading={open && (!labOrderData || !design)}
                onClick={() => {
                    setOpen(true);
                }}
            >
                {children}
            </ButtonWithProgress>
            {labOrderData && design && (
                <DesignEditorRoot
                    order={labOrderData}
                    design={design}
                    open={open}
                    callbacks={{
                        ...submitCallbacks,
                        setWindowOpen: setOpen,
                        onCancel: () => {},
                        onComplete,
                    }}
                    initiallyOpen={false}
                    isTrainingOrder={true}
                    skipDvdForTraining={isFullDesignCourse(trainingOrder)}
                />
            )}
        </>
    );
};
