import {
    isGuidedPreset,
    useNextTabNavigation,
    usePrevTabNavigation,
    useReviewHasStructuredNotes,
    useShouldDisableTabs,
} from '../GuidedWaxup.util';
import { HIDE_POPUP_MODAL_KEY, NAV_BUTTON_STYLES } from '../VisualConstants.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupAction, useGuidedWaxupSelector, useWaxupIsRejected } from '../state/GuidedWaxupState';
import { GuidedWaxupScheduleLiveCall } from './LiveDesignReview/GuidedWaxupScheduleLiveCall.graphql';
import {
    useGetCalendlyPrefillData,
    useRejectionNotes,
} from './LiveDesignReview/GuidedWaxupScheduleLiveCall.hooks.graphql';
import { UnfinishedPresetsMessage } from './UnfinishedPresetsMessage';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { PracticeScreen } from '@orthly/dentin';
import { getFragmentData, VeneerUseOrderRevisionItemsDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import {
    LabsGqlDesignOrderDoctorReviewStatus,
    LabsGqlGuidedWaxupPresetType,
    LabsGqlLiveDdpReviewRequestEntryPoint,
} from '@orthly/graphql-schema';
import {
    Box,
    Button,
    FlossPalette,
    Grid,
    Text,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    styled,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

const useStyles = stylesFactory(() => ({
    navAreaDesktop: {
        marginBottom: 20,
        paddingLeft: 24,
    },
}));

const MobileNavWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '15px',
    gap: '15px',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    borderTop: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    justifyContent: 'center',
    backgroundColor: FlossPalette.WHITE,
    zIndex: 2,
});

const MobileButtonsWrapper = styled(Box, {
    shouldForwardProp: p => p !== 'showPrimaryButton' && p !== 'showSecondaryButton',
})<{ showPrimaryButton: boolean; showSecondaryButton: boolean }>(({ showPrimaryButton, showSecondaryButton }) => ({
    display: 'grid',
    gridTemplateColumns: showPrimaryButton && showSecondaryButton ? '1fr 1fr' : '1fr',
    gap: '15px',
    width: '100%',
}));

const trackNavButtonClick = (buttonText: string) => {
    BrowserAnalyticsClientFactory.Instance?.track('Button Clicked', {
        AssetName: `Guided Waxup Navigation Button - ${buttonText}`,
        AssetType: 'button',
        AssetVersion: '',
        AssetCTAText: buttonText,
    });
};

interface NavAreaProps {
    submit?: () => Promise<unknown>;
}

// eslint-disable-next-line max-lines-per-function
export const GuidedWaxupNavArea: React.VFC<NavAreaProps> = ({ submit }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const {
        enableStructuredDesignReviewNotes,
        onBack,
        selectedTab,
        setSelectedTab,
        selectedDesignRevisionAlreadyReviewed,
        setIsSkipToGeneralViewModalOpen,
        setIsMobileRejectionNoteOpen,
        revisionId,
        internalEvaluation,
        unfinishedPresets,
        selectedDesignRevisionId,
        designRevisionFragments,
        order,
    } = useGuidedWaxupContext();
    const skipToGeneralView = useGuidedWaxupAction('SKIP_TO_GENERAL_VIEW');
    const waxupRejected = useWaxupIsRejected();
    const navigateToNextTab = useNextTabNavigation();
    const navigateToPrevTab = usePrevTabNavigation();
    const guidedPreset = isGuidedPreset(selectedTab);
    const disableNav = useShouldDisableTabs();
    const buttonIsSkipToSubmit = guidedPreset && !selectedDesignRevisionAlreadyReviewed;
    const isStructuredNotesNavButton = enableStructuredDesignReviewNotes && guidedPreset;
    const showPrimaryButton = !selectedDesignRevisionAlreadyReviewed || guidedPreset;
    const showSecondaryButton = buttonIsSkipToSubmit || !guidedPreset;
    const presets = useGuidedWaxupSelector(s => s.presets);
    const disableSubmit = !guidedPreset && unfinishedPresets.length > 0;
    const reviewHasStructuredNotes = useReviewHasStructuredNotes();
    const calendlyPrefill = useGetCalendlyPrefillData();

    const designRevisions = getFragmentData(
        VeneerUseOrderRevisionItemsDesign_FragmentFragmentDoc,
        designRevisionFragments,
    );
    const rejections = _.sumBy(designRevisions, revision =>
        revision.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Rejected ? 1 : 0,
    );
    const rejectionNotes = useRejectionNotes();

    const primaryBtnText = React.useMemo(() => {
        if (guidedPreset) {
            return 'Continue';
        }

        const getBaseButtonText = () => {
            const orderIsPaused = order.hold_details?.hold_is_practice_managed_pause;

            if (waxupRejected) {
                return orderIsPaused ? 'Resume Order and Reject Design' : 'Reject Design';
            }

            return orderIsPaused ? 'Resume Order and Approve Design' : 'Approve Design';
        };

        const buttonText = getBaseButtonText();
        return internalEvaluation ? `${buttonText} (Not recommended. Internal Evaluation)` : buttonText;
    }, [guidedPreset, internalEvaluation, waxupRejected, order.hold_details?.hold_is_practice_managed_pause]);

    const isFirstTab = React.useMemo(() => selectedTab === Object.keys(presets)[0], [presets, selectedTab]);

    const secondaryBtnText = React.useMemo(() => {
        if (isStructuredNotesNavButton) {
            return isFirstTab ? 'Cancel' : 'Previous';
        }

        if (buttonIsSkipToSubmit) {
            return 'Skip to Submit Review';
        }

        if (selectedDesignRevisionAlreadyReviewed && reviewHasStructuredNotes) {
            return 'View Rejection Note';
        }

        if (enableStructuredDesignReviewNotes) {
            return waxupRejected ? 'Modify Rejection Note' : 'Add Rejection Note';
        }

        const generalRejectionNote = presets[selectedTab]?.notes?.trim();
        return selectedDesignRevisionAlreadyReviewed || revisionId !== undefined || generalRejectionNote
            ? 'View Rejection Note'
            : 'Add Rejection Note';
    }, [
        buttonIsSkipToSubmit,
        isFirstTab,
        isStructuredNotesNavButton,
        selectedDesignRevisionAlreadyReviewed,
        reviewHasStructuredNotes,
        enableStructuredDesignReviewNotes,
        revisionId,
        presets,
        selectedTab,
        waxupRejected,
    ]);

    const primaryBtnStartIcon =
        order.hold_details?.hold_is_practice_managed_pause && !guidedPreset ? 'PlayArrowIcon' : undefined;

    React.useEffect(() => {
        if (internalEvaluation) {
            skipToGeneralView({});
            setSelectedTab(LabsGqlGuidedWaxupPresetType.GeneralView);
        }
    }, [internalEvaluation, setSelectedTab, skipToGeneralView]);

    const handleScheduleCompletion = React.useCallback(() => {
        navigate(`/${PracticeScreen.orders}/${order.id}`, { replace: true });
        window.location.reload();
    }, [navigate, order.id]);

    const navigateOrSubmitClick = () => {
        trackNavButtonClick(primaryBtnText);
        if (!guidedPreset) {
            void submit?.();
        } else {
            navigateToNextTab();
        }
    };

    const handleSecondaryClick = React.useCallback(() => {
        if (isStructuredNotesNavButton) {
            trackNavButtonClick(secondaryBtnText);
            if (isFirstTab) {
                onBack();
            } else {
                navigateToPrevTab();
            }
        } else if (buttonIsSkipToSubmit) {
            trackNavButtonClick('Skip to Submit Review');
            if (localStorage.getItem(HIDE_POPUP_MODAL_KEY) === 'true') {
                skipToGeneralView({});
                setSelectedTab(LabsGqlGuidedWaxupPresetType.GeneralView);
            } else {
                setIsSkipToGeneralViewModalOpen(true);
            }
        } else if (isMobile) {
            setIsMobileRejectionNoteOpen(true);
        }
    }, [
        buttonIsSkipToSubmit,
        isFirstTab,
        isMobile,
        isStructuredNotesNavButton,
        navigateToPrevTab,
        onBack,
        secondaryBtnText,
        setIsMobileRejectionNoteOpen,
        setIsSkipToGeneralViewModalOpen,
        setSelectedTab,
        skipToGeneralView,
    ]);

    if (isMobile) {
        return (
            <MobileNavWrapper>
                <MobileButtonsWrapper showPrimaryButton={showPrimaryButton} showSecondaryButton={showSecondaryButton}>
                    {showSecondaryButton && (
                        <Button
                            variant={
                                buttonIsSkipToSubmit || isStructuredNotesNavButton
                                    ? 'secondary-gray'
                                    : 'alert-secondary'
                            }
                            style={NAV_BUTTON_STYLES}
                            disabled={disableNav && !(enableStructuredDesignReviewNotes && isFirstTab)}
                            onClick={handleSecondaryClick}
                        >
                            {secondaryBtnText}
                        </Button>
                    )}
                    {showPrimaryButton && (
                        <Button
                            variant={'primary'}
                            style={NAV_BUTTON_STYLES}
                            startIcon={primaryBtnStartIcon}
                            disabled={disableNav || disableSubmit}
                            onClick={navigateOrSubmitClick}
                        >
                            {primaryBtnText}
                        </Button>
                    )}
                </MobileButtonsWrapper>

                {selectedDesignRevisionId && (
                    <GuidedWaxupScheduleLiveCall
                        order={order}
                        rejections={rejections}
                        revisionId={selectedDesignRevisionId}
                        calendlyPrefill={calendlyPrefill}
                        rejectionNotes={rejectionNotes}
                        entryPoint={LabsGqlLiveDdpReviewRequestEntryPoint.PracticeGuidedWaxupReview}
                        onScheduleCompletion={handleScheduleCompletion}
                    />
                )}
            </MobileNavWrapper>
        );
    }

    return (
        <Grid item container direction={'column'}>
            {disableNav && !enableStructuredDesignReviewNotes && (
                <Grid item className={classes.navAreaDesktop} sx={{ paddingLeft: '10px' }}>
                    <Text variant={'body2'}>One or more required field(s) are missing</Text>
                </Grid>
            )}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px',
                }}
                className={classes.navAreaDesktop}
            >
                {selectedDesignRevisionId && (
                    <GuidedWaxupScheduleLiveCall
                        order={order}
                        rejections={rejections}
                        revisionId={selectedDesignRevisionId}
                        calendlyPrefill={calendlyPrefill}
                        rejectionNotes={rejectionNotes}
                        entryPoint={LabsGqlLiveDdpReviewRequestEntryPoint.PracticeGuidedWaxupReview}
                        onScheduleCompletion={handleScheduleCompletion}
                    />
                )}
                {(isStructuredNotesNavButton || buttonIsSkipToSubmit) && (
                    <Button
                        variant={'secondary-gray'}
                        disabled={disableNav && !(enableStructuredDesignReviewNotes && isFirstTab)}
                        onClick={handleSecondaryClick}
                        sx={{ minWidth: '190px' }}
                    >
                        {secondaryBtnText}
                    </Button>
                )}
                <Button
                    variant={'primary'}
                    disabled={disableNav || disableSubmit}
                    onClick={navigateOrSubmitClick}
                    startIcon={primaryBtnStartIcon}
                    sx={{ minWidth: '190px' }}
                >
                    {primaryBtnText}
                </Button>
                <UnfinishedPresetsMessage />
            </Box>
        </Grid>
    );
};
