/* eslint-disable max-lines */
import type { QcHeatmapRange } from '../ColorRamp';
import type {
    ScanReviewMarginMarkingToolController,
    ScanReviewMarginMarkingToolLiveObjectsProvider,
} from './MarginMarking';
import { ScanReviewMarginLineEditor, ScanReviewMarginMarkingToolActiveState } from './MarginMarking';
import type { ScanReviewInsertionAxisLiveObjectProvider } from './ScanReview.utils';
import type { ScanReviewAppCallbacks, ScanReviewAppState, ScanReviewInitialAppState } from './ScanReviewApp.types';
import { ScanReviewLiveObjectProvidersFactory, ScanReviewToolControllersFactory } from './ScanReviewAppFactories';
import type { ScanReviewInsertionAxis, ScanReviewMarginLine } from './ScanReviewDesignTypes';
import type { ScanReviewCompositeScene } from './ScanReviewSceneTypes';
import type { ScanReviewCompositeViewManager } from './ScanReviewViewTypes';
import {
    ScanReviewPanelType,
    INITIAL_SCAN_REVIEW_BITE_ANALYSIS_HEATMAP_RANGE,
    INITIAL_SCAN_REVIEW_UNDERCUT_HEATMAP_RANGE,
    ScanReviewDisplayType,
    ScanReviewMode,
    ScanReviewViewType,
} from './ScanReviewViewTypes';
import type { ScanReviewUndercutToolController } from './Undercut';
import { ToothUtils, type ToothNumber } from '@orthly/items';
import { Jaw } from '@orthly/shared-types';
import type { MeshBVH } from 'three-mesh-bvh';

export class ScanReviewAppStateManager {
    private mode: ScanReviewMode;
    private displayType: ScanReviewDisplayType;
    private viewType: ScanReviewViewType;
    private heatmapRange: QcHeatmapRange;
    private undercutHeatmapRange: QcHeatmapRange;
    private upperJawVisible: boolean;
    private lowerJawVisible: boolean;
    private marginIsVisible: boolean;
    private marginSketchingModeActive: boolean;
    private insertionAxisIsVisible: boolean;
    private tubMarginLinesEnabled: boolean;
    private toothNumber?: ToothNumber;
    private marginLineToolControllers: Map<ScanReviewPanelType, ScanReviewMarginMarkingToolController> = new Map();
    private undercutToolControllers: Map<ScanReviewPanelType, ScanReviewUndercutToolController> = new Map();
    private marginLineLiveObjectProviders: Map<ScanReviewPanelType, ScanReviewMarginMarkingToolLiveObjectsProvider> =
        new Map();
    private insertionAxisLiveObjectProviders: Map<ScanReviewPanelType, ScanReviewInsertionAxisLiveObjectProvider> =
        new Map();

    private bvhCache: Map<string, MeshBVH> = new Map();

    static create(
        scene: ScanReviewCompositeScene,
        viewManager: ScanReviewCompositeViewManager,
        marginLineEditors: Map<ToothNumber, ScanReviewMarginLineEditor>,
        insertionAxes: Map<ToothNumber, ScanReviewInsertionAxis>,
        callbacks: ScanReviewAppCallbacks,
        initialAppState: ScanReviewInitialAppState,
    ) {
        const defaults: ScanReviewInitialAppState = {
            mode: ScanReviewMode.Review,
            displayType: ScanReviewDisplayType.Scan,
            viewType: ScanReviewViewType.Complete,
            heatmapRange: INITIAL_SCAN_REVIEW_BITE_ANALYSIS_HEATMAP_RANGE,
            undercutHeatmapRange: INITIAL_SCAN_REVIEW_UNDERCUT_HEATMAP_RANGE,
            upperJawVisible: true,
            lowerJawVisible: true,
            isScanOrScanUndercutDisplay: true,
            isStoneModelOrStoneModelUndercutDisplay: false,
            insertionAxisIsVisible: true,
            marginIsVisible: true,
            tubeMarginLinesEnabled: true,
            marginSketchingModeActive: false,
        };

        const app = new ScanReviewAppStateManager(scene, viewManager, marginLineEditors, insertionAxes, callbacks, {
            ...defaults,
            ...initialAppState,
        });
        const appState = app.buildAppState();
        return { app, appState };
    }

    private constructor(
        readonly scene: ScanReviewCompositeScene,
        readonly viewManager: ScanReviewCompositeViewManager,
        private readonly marginLineEditors: Map<ToothNumber, ScanReviewMarginLineEditor>,
        private readonly insertionAxes: Map<ToothNumber, ScanReviewInsertionAxis>,
        private readonly callbacks: ScanReviewAppCallbacks,
        currentAppState: ScanReviewInitialAppState,
    ) {
        this.mode = currentAppState.mode;
        this.displayType = currentAppState.displayType;
        this.viewType = currentAppState.viewType;
        this.heatmapRange = currentAppState.heatmapRange;
        this.undercutHeatmapRange = currentAppState.undercutHeatmapRange;
        this.upperJawVisible = currentAppState.upperJawVisible;
        this.lowerJawVisible = currentAppState.lowerJawVisible;
        this.marginIsVisible = currentAppState.marginIsVisible;
        this.marginSketchingModeActive = currentAppState.marginSketchingModeActive;
        this.insertionAxisIsVisible = currentAppState.insertionAxisIsVisible;
        this.tubMarginLinesEnabled = currentAppState.tubeMarginLinesEnabled;
    }

    private buildAppState(): ScanReviewAppState {
        return {
            mode: this.mode,
            displayType: this.displayType,
            viewType: this.viewType,
            heatmapRange: this.heatmapRange,
            undercutHeatmapRange: this.undercutHeatmapRange,
            upperJawVisible: this.upperJawVisible,
            lowerJawVisible: this.lowerJawVisible,
            isScanOrScanUndercutDisplay:
                this.displayType === ScanReviewDisplayType.Scan ||
                this.displayType === ScanReviewDisplayType.ScanUndercut,
            isStoneModelOrStoneModelUndercutDisplay:
                this.displayType === ScanReviewDisplayType.StoneModel ||
                this.displayType === ScanReviewDisplayType.StoneModelUndercut,
            upperJawInScene: this.scene.upperJawInScene,
            lowerJawInScene: this.scene.lowerJawInScene,
            marginIsVisible: this.marginIsVisible,
            insertionAxisIsVisible: this.insertionAxisIsVisible,
            tubeMarginLinesEnabled: this.tubMarginLinesEnabled,
            toothNumber: this.toothNumber,
            insertionAxes: this.insertionAxes,
            currentMarginLine: this.toothNumber
                ? this.marginLineEditors.get(this.toothNumber)?.currentEditedMarginLine
                : undefined,
            currentMarginLineEditor: this.toothNumber ? this.marginLineEditors.get(this.toothNumber) : undefined,
            currentInsertionAxis: this.toothNumber ? this.insertionAxes.get(this.toothNumber) : undefined,
            marginLineToolControllers: this.marginLineToolControllers,
            undercutToolControllers: this.undercutToolControllers,
            marginLineLiveObjectProviders: this.marginLineLiveObjectProviders,
            insertionAxisLiveObjectProviders: this.insertionAxisLiveObjectProviders,
            marginSketchingModeActive: this.marginSketchingModeActive,
        };
    }

    private updateAppState(): void {
        const currentAppState = this.buildAppState();
        const liveObjectProvidersFactory = new ScanReviewLiveObjectProvidersFactory(this.scene, currentAppState);
        const toolControllersFactory = new ScanReviewToolControllersFactory(
            this.scene,
            this.viewManager,
            currentAppState,
            this.callbacks,
            this.bvhCache,
        );

        liveObjectProvidersFactory.updateInsertionAxisLiveObjectProviders();
        liveObjectProvidersFactory.updateMarginLineLiveObjectProviders();
        toolControllersFactory.updateUndercutToolControllers();
        toolControllersFactory.updateMarginMarkingToolControllers(
            a => (this.marginSketchingModeActive = a === ScanReviewMarginMarkingToolActiveState.Sketch),
        );
        this.callbacks.setAppStateRef.current?.(this.buildAppState());
    }

    deleteMarginLine(unn: ToothNumber) {
        this.marginLineEditors.delete(unn);
        this.updateAppState();
    }

    deleteInsertionAxis(unn: ToothNumber) {
        this.insertionAxes.delete(unn);
        this.updateAppState();
    }

    addMarginLine(marginLine: ScanReviewMarginLine) {
        this.marginLineEditors.set(marginLine.unn, new ScanReviewMarginLineEditor(marginLine));
    }

    addInsertionAxis(insertionAxis: ScanReviewInsertionAxis) {
        this.insertionAxes.set(insertionAxis.unn, insertionAxis);
    }

    setHeatmapRange(newHeatmapRange: QcHeatmapRange) {
        this.heatmapRange = newHeatmapRange;
        this.scene.updateHeatmapRange(newHeatmapRange);
        this.updateAppState();
    }

    setUndercutHeatmapRange(newHeatmapRange: QcHeatmapRange) {
        this.undercutHeatmapRange = newHeatmapRange;
        this.scene.updateUndercutHeatmapRange(newHeatmapRange);
        this.updateAppState();
    }

    setViewType(newViewType: ScanReviewViewType) {
        switch (newViewType) {
            case ScanReviewViewType.Complete:
            case ScanReviewViewType.SideBySide: {
                if (this.displayType === ScanReviewDisplayType.ScanUndercut) {
                    this.setDisplayType(ScanReviewDisplayType.Scan);
                } else if (this.displayType === ScanReviewDisplayType.StoneModelUndercut) {
                    this.setDisplayType(ScanReviewDisplayType.StoneModel);
                }
                this.setMode(ScanReviewMode.Review);
                this.viewType = newViewType;
                break;
            }
            case ScanReviewViewType.Single: {
                this.viewType = newViewType;
                break;
            }
        }
        this.updateAppState();
    }

    setMode(newMode: ScanReviewMode) {
        switch (newMode) {
            case ScanReviewMode.Review: {
                if (this.displayType === ScanReviewDisplayType.ScanUndercut) {
                    this.scene.setDisplayType(ScanReviewDisplayType.Scan, this.heatmapRange);
                    this.displayType = ScanReviewDisplayType.Scan;
                } else if (this.displayType === ScanReviewDisplayType.StoneModelUndercut) {
                    this.scene.setDisplayType(ScanReviewDisplayType.StoneModel, this.heatmapRange);
                    this.displayType = ScanReviewDisplayType.StoneModel;
                }
                break;
            }
            case ScanReviewMode.MarginMarking: {
                if (this.insertionAxisIsVisible) {
                    this.scene.setDisplayType(ScanReviewDisplayType.ScanUndercut, this.heatmapRange);
                    this.displayType = ScanReviewDisplayType.ScanUndercut;
                } else {
                    this.scene.setDisplayType(ScanReviewDisplayType.Scan, this.heatmapRange);
                    this.displayType = ScanReviewDisplayType.Scan;
                }
                this.setJawVisibilityBasedOnToothNumber();
                break;
            }
            case ScanReviewMode.Undercut: {
                if (this.displayType === ScanReviewDisplayType.StoneModel) {
                    this.scene.setDisplayType(ScanReviewDisplayType.StoneModelUndercut, this.heatmapRange);
                    this.displayType = ScanReviewDisplayType.StoneModelUndercut;
                } else {
                    this.scene.setDisplayType(ScanReviewDisplayType.ScanUndercut, this.heatmapRange);
                    this.displayType = ScanReviewDisplayType.ScanUndercut;
                }
                this.setJawVisibilityBasedOnToothNumber();
                break;
            }
        }
        this.mode = newMode;
        this.updateAppState();
    }

    setDisplayType(newDisplayType: ScanReviewDisplayType) {
        switch (newDisplayType) {
            case ScanReviewDisplayType.Scan: {
                this.switchToScanDisplay();
                break;
            }
            case ScanReviewDisplayType.StoneModel: {
                this.switchToStoneModelDisplay();
                break;
            }
            case ScanReviewDisplayType.BiteAnalysis: {
                this.switchToBiteAnalyisDisplay();
                break;
            }
            case ScanReviewDisplayType.Segmentation: {
                this.switchToSegmentationDisplay();
                break;
            }
        }
        this.updateAppState();
    }

    private switchToScanDisplay() {
        switch (this.mode) {
            case ScanReviewMode.Review:
                this.scene.setDisplayType(ScanReviewDisplayType.Scan, this.heatmapRange);
                this.displayType = ScanReviewDisplayType.Scan;
                break;
            case ScanReviewMode.MarginMarking: {
                if (this.insertionAxisIsVisible) {
                    this.scene.setDisplayType(ScanReviewDisplayType.ScanUndercut, this.heatmapRange);
                    this.displayType = ScanReviewDisplayType.ScanUndercut;
                } else {
                    this.scene.setDisplayType(ScanReviewDisplayType.Scan, this.heatmapRange);
                    this.displayType = ScanReviewDisplayType.Scan;
                }
                break;
            }
            case ScanReviewMode.Undercut: {
                this.scene.setDisplayType(ScanReviewDisplayType.ScanUndercut, this.heatmapRange);
                this.displayType = ScanReviewDisplayType.ScanUndercut;
                break;
            }
        }
    }

    private switchToStoneModelDisplay() {
        switch (this.mode) {
            case ScanReviewMode.Review: {
                this.scene.setDisplayType(ScanReviewDisplayType.StoneModel, this.heatmapRange);
                this.displayType = ScanReviewDisplayType.StoneModel;
                break;
            }
            case ScanReviewMode.MarginMarking: {
                this.scene.setDisplayType(ScanReviewDisplayType.StoneModel, this.heatmapRange);
                this.displayType = ScanReviewDisplayType.StoneModel;
                this.mode = ScanReviewMode.Review;
                break;
            }
            case ScanReviewMode.Undercut: {
                this.scene.setDisplayType(ScanReviewDisplayType.StoneModelUndercut, this.heatmapRange);
                this.displayType = ScanReviewDisplayType.StoneModelUndercut;
                break;
            }
        }
    }

    private switchToBiteAnalyisDisplay() {
        switch (this.mode) {
            case ScanReviewMode.Review: {
                this.scene.setDisplayType(ScanReviewDisplayType.BiteAnalysis, this.heatmapRange);
                this.displayType = ScanReviewDisplayType.BiteAnalysis;
                break;
            }
            case ScanReviewMode.MarginMarking:
            case ScanReviewMode.Undercut: {
                this.scene.setDisplayType(ScanReviewDisplayType.BiteAnalysis, this.heatmapRange);
                this.displayType = ScanReviewDisplayType.BiteAnalysis;
                this.mode = ScanReviewMode.Review;
                break;
            }
        }
    }

    private switchToSegmentationDisplay() {
        switch (this.mode) {
            case ScanReviewMode.Review: {
                this.scene.setDisplayType(ScanReviewDisplayType.Segmentation, null);
                this.displayType = ScanReviewDisplayType.Segmentation;
                break;
            }
            case ScanReviewMode.MarginMarking:
            case ScanReviewMode.Undercut: {
                this.scene.setDisplayType(ScanReviewDisplayType.Segmentation, null);
                this.displayType = ScanReviewDisplayType.Segmentation;
                this.mode = ScanReviewMode.Review;
                break;
            }
        }
    }

    setUpperJawVisible(visible: boolean) {
        this.scene.setUpperJawVisibility(visible);
        this.upperJawVisible = visible;
        this.updateAppState();
    }

    setLowerJawVisible(visible: boolean) {
        this.scene.setLowerJawVisibility(visible);
        this.lowerJawVisible = visible;
        this.updateAppState();
    }

    setInsertionAxisVisible(visible: boolean) {
        this.insertionAxisIsVisible = visible;

        // If this gets called from a click handler, we need to set display type.
        // This allows us to properly switch between Scan and ScanUndercut displays.

        if (this.mode === ScanReviewMode.MarginMarking) {
            this.setDisplayType(ScanReviewDisplayType.Scan);
        }

        // Update tube margin line display if applicable
        if (this.tubMarginLinesEnabled && this.toothNumber) {
            for (const marginLineLiveObjectProvider of this.marginLineLiveObjectProviders.values()) {
                visible
                    ? marginLineLiveObjectProvider.updateMeshTube(this.insertionAxes.get(this.toothNumber), false)
                    : marginLineLiveObjectProvider.updateMeshTube();
            }
        }

        this.updateAppState();
    }

    setTubeMarginLinesEnabled(enabled: boolean) {
        this.tubMarginLinesEnabled = enabled;
        this.updateAppState();
    }

    setMarginIsVisible(visible: boolean) {
        this.marginIsVisible = visible;
        this.updateAppState();
    }

    setToothNumber(toothNumber?: ToothNumber) {
        this.toothNumber = toothNumber;
        if (this.toothNumber === undefined) {
            this.setMode(ScanReviewMode.Review);
            return;
        } else if (this.mode === ScanReviewMode.MarginMarking || this.mode === ScanReviewMode.Undercut) {
            this.setJawVisibilityBasedOnToothNumber();
            const jaw = ToothUtils.toothIsLower(this.toothNumber) ? Jaw.LOWER : Jaw.UPPER;
            const insertionAxis = this.insertionAxes.get(this.toothNumber);
            if (insertionAxis) {
                this.scene.updateUndercutDisplay(jaw, insertionAxis);
            }
        }
        this.updateAppState();
    }

    private setJawVisibilityBasedOnToothNumber() {
        if (this.toothNumber) {
            if (ToothUtils.toothIsLower(this.toothNumber)) {
                this.scene.setLowerJawVisibility(true);
                this.lowerJawVisible = true;

                this.scene.setUpperJawVisibility(false);
                this.upperJawVisible = false;
            } else {
                this.scene.setLowerJawVisibility(false);
                this.lowerJawVisible = false;

                this.scene.setUpperJawVisibility(true);
                this.upperJawVisible = true;
            }
        }
    }

    handleInsertionAxisUpdate() {
        this.updateAppState();
    }

    updateInsertionAxisFromView() {
        if (this.mode === ScanReviewMode.Undercut) {
            // For now we only support undercut tool in isolate panel.
            // Easy to fix later.
            const controller = this.undercutToolControllers.get(ScanReviewPanelType.Isolated);
            controller?.updateInsertionAxisFromView();
            this.updateAppState();
        }
    }

    resetMarginLines() {
        if (this.mode === ScanReviewMode.MarginMarking) {
            // For now we only support margin marking in isolated panel.
            // Easy to fix later.
            const controller = this.marginLineToolControllers.get(ScanReviewPanelType.Isolated);

            // This is a bit of a hack. The margin line editors are shared among all
            // controllers, but we don't want an undo per-panel.
            controller?.reset();
        }
    }

    undoMarginLineEdits() {
        if (this.mode === ScanReviewMode.MarginMarking) {
            // For now we only support margin marking in isolated panel.
            // Easy to fix later.
            const controller = this.marginLineToolControllers.get(ScanReviewPanelType.Isolated);

            // This is a bit of a hack. The margin line editors are shared among all
            // controllers, but we don't want an undo per-panel.
            controller?.undo();
        }
    }
}
