import { ButtonWithProgress } from './ButtonWithProgress';
import { useSubmitCallbacks } from './DesignTrainingStartDesignEditorButton.graphql';
import type { DesignTrainingOrderFragment } from '@orthly/graphql-inline-react';
import { useOrder } from '@orthly/graphql-react';
import { Button, type ButtonProps } from '@orthly/ui-primitives';
import { useDesignOrderRevision, ToolGroup, DesignEditorRoot } from '@orthly/veneer';
import React from 'react';

export const DesignTrainingStartFinishingButton: React.VFC<{
    trainingOrder: DesignTrainingOrderFragment;
    onComplete: () => void;
    /** Button children. Usually just the button text. */
    children?: React.ReactNode;
    /** Whether to prefetch order and design revision data or wait until the user clicks the button. Default is `false`. */
    prefetch?: boolean;
    /** The button component. Default is `Button`. */
    ButtonComponent?: React.ComponentType<ButtonProps>;
    /** The button variant. Default is `primary`. */
    buttonVariant?: ButtonProps['variant'];
}> = props => {
    const {
        trainingOrder,
        onComplete,
        children,
        prefetch = false,
        ButtonComponent = Button,
        buttonVariant = 'primary',
    } = props;

    if (!trainingOrder.designRevisionId) {
        const message = 'Design revision ID is required for a Dandy Finishing training order.';
        console.error(message, trainingOrder);
        throw new Error(message);
    }

    const [open, setOpen] = React.useState<boolean>(false);
    // Skip queries when not prefetching and the user hasn't clicked the button to start.
    const skip = !prefetch && !open;

    let { order: labOrderData } = useOrder(trainingOrder.orderId, { skip });
    if (labOrderData) {
        // TODO: This is pretty hacky. Maybe we can explicitly tell the finishing app where to start instead.
        labOrderData = {
            ...labOrderData,
            fulfillment_workflow: { ...labOrderData.fulfillment_workflow, active_task: null },
            patient: { ...labOrderData.patient, first_name: `Training #${trainingOrder.orderNumber}`, last_name: '' },
        };
    }
    const { data: designRevisionData } = useDesignOrderRevision(trainingOrder.designRevisionId, { skip });
    const design = designRevisionData?.getDesignOrderDesignRevisionById;

    const submitCallbacks = useSubmitCallbacks(trainingOrder.orderId, trainingOrder.id);

    return (
        <>
            <ButtonWithProgress
                ButtonComponent={ButtonComponent}
                variant={buttonVariant}
                loading={open && (!labOrderData || !design)}
                onClick={() => {
                    setOpen(true);
                }}
            >
                {children}
            </ButtonWithProgress>
            {labOrderData && design && (
                <DesignEditorRoot
                    order={labOrderData}
                    design={design}
                    open={open}
                    callbacks={{
                        ...submitCallbacks,
                        setWindowOpen: setOpen,
                        onCancel: () => {},
                        onComplete,
                    }}
                    initiallyOpen={false}
                    isTrainingOrder={true}
                    allowedTools={[ToolGroup.SculptAndTransform]}
                />
            )}
        </>
    );
};
