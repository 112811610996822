/* eslint-disable max-lines */
import type { LabsGqlMutation, LabsGqlQuery } from './graphql-types.generated';
import { GqlOperationReturnTypes } from './return-types.generated';
import type { DandyEngTeam } from '@orthly/shared-types';
import type { OperationDefinitionNode } from 'graphql';

/**
 * This file is a mapping of ownership from graphql operations to teams. These are used in sentry middleware, which tags
 * operations with the correct team, so that alerts can be routed correctly
 *
 * IF YOU SEE A TYPE ERROR IN THIS FILE, ITS PROBABLY BECAUSE YOU ADDED A QUERY OR MUTATION AND IT NEEDS TO BE MAPPED
 */

type QueryOwnershipMap = Record<keyof Omit<LabsGqlQuery, '__typename'>, DandyEngTeam>;
type MutationOwnershipMap = Record<keyof Omit<LabsGqlMutation, '__typename'>, DandyEngTeam>;

export const deprecatedQueryOwnershipMap = {
    getAllPreferenceSets: 'practice_admin',
    getZendeskAgentsFromGroup: 'practice_admin',
    getOrderIdFromOrderNumber: 'practice_admin',

    listPartialsFulfillmentNeedsFeedbackOrders: 'ordering',
    listPartialsFulfillmentNeedsReturnLabelOrders: 'ordering',
    waxupRecommendationV2: 'ordering',

    getOrderAutomateResult: 'cad_3d',
    ordersByActiveDesignTask: 'cad_3d',

    getLabSalesOrderById: 'production_management',
    getLabSalesOrderIdByOrderNumber: 'production_management',
    getLabSalesOrdersBySplitId: 'production_management',
    getLabSalesOrdersInRefabChain: 'production_management',
} satisfies Partial<QueryOwnershipMap>;

export const queryOwnershipMap: QueryOwnershipMap = {
    ...deprecatedQueryOwnershipMap,

    // billing
    active_price_presets: 'billing',
    calculateCreditCardProcessingFeeForInvoiceAmounts: 'billing',
    fetchOverdueInvoices: 'billing',
    fetchPartnerBillingChangelog: 'billing',
    getActiveContractAndAssociatedOrgsByOrganizationId: 'billing',
    getAllBillingCreditCategories: 'billing',
    getBillingContactEmailsForInvoices: 'billing',
    getBillingCreditCategoriesById: 'billing',
    getCanceledLabOrders: 'billing',
    getCreditCardProcessingFeeAmountCentsForInvoice: 'billing',
    getCreditCardProcessingFeeAmountCentsForInvoiceIds: 'billing',
    getLabAssignmentsByOrderId: 'billing',
    getLabCaseCompletion: 'billing',
    getLabCaseLedgerData: 'billing',
    getLabCaseLedgerSummary: 'billing',
    getLabMetalCost: 'billing',
    getLabPriceDefinitions: 'billing',
    getLabPriceLabPrices: 'billing',
    getLabPricePartnerPrices: 'billing',
    getLabPrices: 'billing',
    getLabsPaymentMethods: 'billing',
    getMetalMaterial: 'billing',
    getOrderHasPendingPayment: 'billing',
    getNextInvoiceStatusForContractOrgs: 'billing',
    getOrderPricesByOrderId: 'billing',
    getOrderPriceEntitiesByOrderId: 'billing',
    getOrderPriceEntitiesByOrderIds: 'billing',
    getOrganizationPriceConfigs: 'billing',
    getOutstandingBalanceCents: 'billing',
    getPartnerBillingAccount: 'billing',
    getPracticeContractV2ByOrganizationId: 'billing',
    getTieredPricingConfig: 'billing',
    listAddressesForContract: 'billing',
    listInvoicesWithItemSummariesForActiveContract: 'billing',
    listInvoiceItemsForExport: 'billing',
    listOrganizationStaffBillingContacts: 'billing',
    listPartnerBillingAccounts: 'billing',
    listInvoicePaymentsForExport: 'billing',
    listPracticeBillingContactUsers: 'billing',
    listPracticeContractsV2ByOrganizationId: 'billing',
    listPrices: 'billing',
    listPricesWithPracticeCounts: 'billing',
    listSalesforcePriceNameMappings: 'billing',
    listSalesforcePriceNameMappingsForLabPrices: 'billing',
    listZeroOutModelsBulkPricingJobs: 'billing',
    partnerBillingCurrentState: 'billing',
    partnerPrices: 'billing',
    previewPracticePendingInvoiceItemsForActiveContract: 'billing',
    getPracticeOrderingPauseInformation: 'billing',
    getShippingAddressByPracticeID: 'billing',
    bulkLoadPracticePrices: 'billing',
    previewPracticePendingInvoiceItems: 'billing',
    listInvoiceItemsForInvoice: 'billing',
    listInvoicesWithItemSummariesForOrganization: 'billing',
    listInvoicesForOrganization: 'billing',
    listInvoiceCreditsForOrganization: 'billing',
    listOverdueInvoices: 'billing',
    calculateCreditCardProcessingFeeForAmount: 'billing',
    getInvoiceById: 'billing',
    getNextInvoiceStatus: 'billing',
    getOrderLedger: 'billing',
    getLastInvoiceEmailForOrganization: 'billing',
    needsBillingSummaryEmail: 'billing',
    needsBillingSummaryForContract: 'billing',
    listRefundCategories: 'billing',
    previewCreditAndRefundOptionsForOrderCancellation: 'billing',
    getCreditById: 'billing',
    getProductCatalogEntry: 'billing',
    listProductCatalogEntries: 'billing',
    getProductCatalogEntryWithDetails: 'billing',
    listProductCatalogEntriesWithDetails: 'billing',
    getProductCatalogPrice: 'billing',
    listProductCatalogPrices: 'billing',
    getContractAndAssociatedOrgsByOrganizationId: 'billing',
    getPartnerBillingAccountsForRelevantContract: 'billing',
    getOrderPriceHistoryItems: 'billing',
    getInvoiceHistoryItems: 'billing',
    calculatePracticeInvoicedSpendOverPeriod: 'billing',
    getFullyResolvedPracticeContractsV2ByPracticeId: 'billing',
    listInvoicesWithItemSummariesForEnterprise: 'billing',
    getOrganizationWithDescendantsAndStaff: 'billing',

    // product_lines
    getAllPendingVariantFeedbackForPractice: 'product_lines',
    getAllPendingVariantFeedback: 'product_lines',
    getShadeSuggestionWithPhotosByShadeCaptureId: 'product_lines',
    getPerfectShadeAppLaunch: 'product_lines',

    // practice_admin
    labOrderCountByStatus: 'practice_admin',
    practiceOrderCountsOverview: 'practice_admin',
    slimOrderInboxItems: 'practice_admin',
    canDeleteDeliveryAddress: 'practice_admin',
    checkPracticeAdmins: 'practice_admin',
    exchangeMultiAuthLegacyToken: 'practice_admin',
    fetchAllPracticeEmployees: 'practice_admin',
    fetchDoctors: 'practice_admin',
    fetchPracticeStaff: 'practice_admin',
    fetchPracticeStaffUsers: 'practice_admin',
    fetchStaffForSelectionScreen: 'practice_admin',
    findCRMTickets: 'practice_admin',
    findCRMUsers: 'practice_admin',
    findLastChatForEntity: 'practice_admin',
    getAllPracticeContactsIncludingExternal: 'practice_admin',
    getAllScannersWithSearch: 'practice_admin',
    getCRMChatConversation: 'practice_admin',
    getCRMChatUser: 'practice_admin',
    getCRMPracticeMetadata: 'practice_admin',
    getSetupChampionBySalesforceID: 'practice_admin',
    getCRMTicket: 'practice_admin',
    getCRMTicketCommentAttachment: 'practice_admin',
    getCRMTicketComments: 'practice_admin',
    getConnectedPreferencesByEmailAndPhone: 'practice_admin',
    getDoctorPreferencesById: 'practice_admin',
    getDoctorPreferencesByToken: 'practice_admin',
    getDoesUserPreferPasswordLogin: 'practice_admin',
    getEmailPreviewByNotificationLogId: 'practice_admin',
    getIsFcmTrainingComplete: 'practice_admin',
    getIsPracticeByod: 'practice_admin',
    checkCsSchedule: 'practice_admin',
    getLinkedDoctorPreferences: 'practice_admin',
    getLoginOptions: 'practice_admin',
    getMembershipLogin: 'practice_admin',
    getNonDoctorStaffById: 'practice_admin',
    getNonDoctorStaffByPartnerId: 'practice_admin',
    getNotificationLogsWithEvents: 'practice_admin',
    getOnboardingSteps: 'practice_admin',
    getOnboardingStepsV2: 'practice_admin',
    getOrCreatePracticeSupport: 'practice_admin',
    getOrderTrackingHoldMessage: 'practice_admin',
    getOrderTrackingHoldTitle: 'practice_admin',
    getOrgAdmins: 'practice_admin',
    getOrgPrimaryAdmin: 'practice_admin',
    getOrganization: 'practice_admin',
    getOrganizationByExternalAccount: 'practice_admin',
    getAllOrganizationsCsv: 'practice_admin',
    getOrganizationWithAncestors: 'practice_admin',
    getOrganizationWithDescendants: 'practice_admin',
    getOrganizationsForUser: 'practice_admin',
    getPaginatedOrganizations: 'practice_admin',
    getPartnerPreferenceSets: 'practice_admin',
    getPaginatedUsers: 'practice_admin',
    getPartnerPreferences: 'practice_admin',
    getPatient: 'practice_admin',
    getPatientSharesByOrgId: 'practice_admin',
    getPracticeDetails: 'practice_admin',
    getPracticeEquipmentData: 'practice_admin',
    getPracticeInteractions: 'practice_admin',
    getPracticeRequests: 'practice_admin',
    getPracticeStaffPreferencesById: 'practice_admin',
    getPracticeTrainingDate: 'practice_admin',
    getPreferencesByIds: 'practice_admin',
    getPreferencesByStaffMemberIds: 'practice_admin',
    getRecentReviews: 'practice_admin',
    getRefabRateBySkuForPartner: 'practice_admin',
    getRegistrationURL: 'practice_admin',
    getReviews: 'practice_admin',
    getSavedSearchById: 'practice_admin',
    getScannersByPractice: 'practice_admin',
    getScheduledLiveCasePlanningRequestNotificationInfo: 'practice_admin',
    getScheduledLiveDdpReviewRequestNotificationInfo: 'practice_admin',
    getSkusForPartner: 'practice_admin',
    getSmsContentsByNotificationLogId: 'practice_admin',
    getStaffActivatedAtFromPreferencesId: 'practice_admin',
    getStaffForUser: 'practice_admin',
    getStaffMemberById: 'practice_admin',
    getStaffMemberForImpersonate: 'practice_admin',
    getStaffMembersByIds: 'practice_admin',
    getStaffMemberOnboardingPreferences: 'practice_admin',
    getStaffMembersOnboardingPreferences: 'practice_admin',
    getStageByPracticeId: 'practice_admin',
    getCRMPractice: 'practice_admin',
    getTrainingStartTime: 'practice_admin',
    getUser: 'practice_admin',
    getUserFromDrPrefId: 'practice_admin',
    getUserByMobilePhoneNumber: 'practice_admin',
    getUserReadEvents: 'practice_admin',
    googleJwtLogin: 'platform',
    isAccountActivated: 'practice_admin',
    labOrderRefabRates: 'practice_admin',
    listAddresses: 'practice_admin',
    listChatMessages: 'practice_admin',
    listOrgUsers: 'practice_admin',
    listOrganizationSummaries: 'practice_admin',
    listOrganizations: 'practice_admin',
    listOrganizationsWithAncestors: 'practice_admin',
    listOrganizationsWithAncestorsSummary: 'practice_admin',
    listOrganizationsWithDescendants: 'practice_admin',
    listOrganizationsWithDescendantsSummary: 'practice_admin',
    listOrganizationsWithHierarchySummary: 'practice_admin',
    listPalateOrderFilters: 'practice_admin',
    listPartnerPreferences: 'practice_admin',
    listPracticeNames: 'practice_admin',
    listPracticeSupport: 'practice_admin',
    listUserOrganizations: 'practice_admin',
    listUserPositions: 'practice_admin',
    listUsers: 'practice_admin',
    listUsersPositions: 'practice_admin',
    listSurgicalGuideDrillKits: 'practice_admin',
    partnerSessionForScanner: 'practice_admin',
    patient_portal_lab_order: 'practice_admin',
    patientPortalPatientShare: 'practice_admin',
    phoneNumberPasswordLogin: 'practice_admin',
    practiceInboxActiveOrders: 'practice_admin',
    practiceOrdersCount: 'practice_admin',
    scan_files_for_patient: 'practice_admin',
    singleAddress: 'practice_admin',
    staffMemberForPreferences: 'practice_admin',
    timelineV2Items: 'practice_admin',
    userExistsWithMobileNumber: 'practice_admin',
    zendeskLSRTicketForScan: 'practice_admin',
    zendeskUser: 'practice_admin',
    getOrderPracticeListViewEntriesByIds: 'practice_admin',
    practiceSearchOrderIds: 'practice_admin',
    getPracticeOnboarding: 'practice_admin',
    getLiveDdpReviewRequest: 'practice_admin',
    searchPracticePatients: 'practice_admin',
    getPatientDetails: 'practice_admin',
    listPracticePatientsWithSortTools: 'practice_admin',
    getIteroCompanyByPracticeId: 'practice_admin',
    getIteroCompaniesByPracticeIds: 'practice_admin',
    getIteroUserByStaffId: 'practice_admin',
    getIteroUsersByStaffIds: 'practice_admin',
    getActiveVideoDdpReviewRequest: 'practice_admin',
    getFulfilledVideoDdpReviewRequest: 'practice_admin',
    calendlyGetNextAvailableLdrTime: 'practice_admin',
    getLdrCapacityStats: 'practice_admin',
    getCalendlySchedulingUrl: 'practice_admin',
    isOrderEligibleForLiveDesignReview: 'practice_admin',
    areItemsEligibleForLiveDesignReview: 'practice_admin',
    getScheduledLiveLabTechMeetingRequestNotificationInfo: 'practice_admin',
    isOrderEligibleForLiveLabTechMeeting: 'practice_admin',
    getLiveLabTechMeetingUrl: 'practice_admin',
    getCalendlyEventTime: 'practice_admin',

    // ordering
    alignerPhotoUploaded: 'ordering',
    checkScannerConnected: 'ordering',
    getAlignerTreatmentPlan: 'ordering',
    getAllScanners: 'ordering',
    getCaseReadiness: 'ordering',
    getCaseReadinessCases: 'ordering',
    getChairsideFirebaseUploadUrl: 'ordering',
    getChairsideFirebaseDownloadUrl: 'ordering',
    getDoctorRequest: 'ordering',
    getFeatureFlagValueByOrganizationId: 'ordering',
    getFirebaseToken: 'ordering',
    getImplantById: 'ordering',
    getIteroIntegrationAuthDetails: 'ordering',
    getIteroSettingsOfCurrentUser: 'ordering',
    getMostFrequentlyUsedImplantManufacturers: 'ordering',
    getNextPartnerOrderNumber: 'ordering',
    getOwnImpersonatedShortenedLink: 'ordering',
    getReasonCodeAdditionalInfoRequestsAndCounts: 'ordering',
    getReasonCodeCategoriesAndCounts: 'ordering',
    getReview: 'ordering',
    getReviewTagCategories: 'ordering',
    getScanbodies: 'ordering',
    getScanbodiesByIds: 'ordering',
    getScanbodyRequestById: 'ordering',
    getScanbodyRequests: 'ordering',
    getScanbodyRequestsInLastNDays: 'ordering',
    getScanbodyRequestsInLast30Days: 'ordering',
    getShortenedLink: 'ordering',
    getStoragePathConfig: 'ordering',
    getTryInFeedbackForOrder: 'ordering',
    getUnusedCaseReadinessCases: 'ordering',
    lastMatchingOrderByUnitType: 'ordering',
    listAbutmentPartByImplantAndScanbody: 'ordering',
    listAbutmentParts: 'ordering',
    listPendingIteroOrdersForPortalNotifications: 'ordering',
    listPendingReviewLabOrderIds: 'ordering',
    listPracticePatients: 'ordering',
    listReasonCodes: 'ordering',
    listReviewTags: 'ordering',
    parse_3oxz_3ox: 'ordering',
    parse_3oxz_case: 'ordering',
    parse_3oxz_from_filepath: 'ordering',
    parse_3oxz_from_files: 'ordering',
    pendingAlignerCaseForScanExport: 'ordering',
    pendingReviewForOrder: 'ordering',
    practiceOrderIdsByStatus: 'ordering',
    reasonCodesForOrderItem: 'ordering',
    scan_file: 'ordering',
    scan_files: 'ordering',
    uploadedAlignerPhotoGCSPaths: 'ordering',
    uploadedAlignerPhotoTypes: 'ordering',

    // platform
    deleteFile: 'platform',
    exchangeLegacyToken: 'platform',
    getImpersonationToken: 'platform',
    getOwnImpersonationToken: 'platform',
    getSignedDownloadUrl: 'platform',
    getSignedUploadUrl: 'platform',
    passwordLogin: 'platform',
    refreshToken: 'platform',

    // cad_3d
    getCadSmileLibraryById: 'cad_3d',
    getAllCadSmileLibraries: 'cad_3d',
    debugDesignerAutoAssignment: 'cad_3d',
    designStaffDetailedStatus: 'cad_3d',
    designStaffStatusBulk: 'cad_3d',
    getAbutmentPart: 'cad_3d',
    getActiveInternalDesignTask: 'cad_3d',
    getActiveInternalDesignTasks: 'cad_3d',
    getAllDesignOrderDesignRevisions: 'cad_3d',
    getDesignFileDmeList: 'cad_3d',
    getDesignOrderDesignRevisionById: 'cad_3d',
    getDesignOrderDesignRevisionsByTaskIds: 'cad_3d',
    getDesignQaEvaluationById: 'cad_3d',
    getDesignQaEvaluationsByOrderId: 'cad_3d',
    getDesignStaff: 'cad_3d',
    getDesignStaffFocusAreasByDesigner: 'cad_3d',
    getDesignStaffTeams: 'cad_3d',
    getDesignTrainingEnrolledCourse: 'cad_3d',
    getInjectionMoldFiles: 'cad_3d',
    getLatestDesignOrderDesignRevision: 'cad_3d',
    getLatestDesignOrderDesignRevisionsForOrders: 'cad_3d',
    getLatestManufacturerFilesForOrders: 'cad_3d',
    getLatestMergeAndStitchFilesForOrder: 'cad_3d',
    getMyPendingDesignQaEvaluations: 'cad_3d',
    getRemovableManufacturingFiles: 'cad_3d',
    listDesignReviewFields: 'cad_3d',
    listDesignTrainingCoursesForEvaluation: 'cad_3d',
    listFilteredDesigners: 'cad_3d',
    listMatchingDesignersForOrder: 'cad_3d',
    listMyDesignTasks: 'cad_3d',
    listMyDesignTrainingCourses: 'cad_3d',
    listThreeshapeDmes: 'cad_3d',
    taskAssignmentTimeline: 'cad_3d',
    validateDesignOrderRevisionUpload: 'cad_3d',

    // manufacturing
    actionTypesWithCategoryInfo: 'manufacturing',
    biasRulesForDoctor: 'manufacturing',
    capacityRulesForManufacturer: 'manufacturing',
    debugRoutingDecision: 'manufacturing',
    designRulesForManufacturer: 'manufacturing',
    estimatedDeliveryDate: 'manufacturing',
    filteredTickets: 'manufacturing',
    getActionCapabilities: 'manufacturing',
    getActionCategories: 'manufacturing',
    getActionTypeById: 'manufacturing',
    getActionCategoryById: 'manufacturing',
    getActionTypes: 'manufacturing',
    getBatchShipManifest: 'manufacturing',
    getCapacityRuleLoads: 'manufacturing',
    getCartDeliveryOptions: 'manufacturing',
    getCompletedStagesForOrder: 'manufacturing',
    getImplantTypes: 'manufacturing',
    getImplantTypesLite: 'manufacturing',
    getManufacturerPreferencesPreferences: 'manufacturing',
    getManufacturerProfile: 'manufacturing',
    getManufacturingBookingById: 'manufacturing',
    getNonFavoritedSavedSearches: 'manufacturing',
    getOrderByQcSearch: 'manufacturing',
    getOrderSlaChanges: 'manufacturing',
    getOrderSlaStageStatuses: 'manufacturing',
    getOrderTickets: 'manufacturing',
    getOrderTicketById: 'manufacturing',
    getOrdersForManufacturer: 'manufacturing',
    getOrdersForShipment: 'manufacturing',
    getPartnerScanners: 'manufacturing',
    getQCIssuesForOrder: 'manufacturing',
    getQCItemsForOrder: 'manufacturing',
    getQcWarningsForOrder: 'manufacturing',
    getRefabCategoriesAndCounts: 'manufacturing',
    getRoutingRulesForOrder: 'manufacturing',
    getSavedSearches: 'manufacturing',
    getShipmentsForOrder: 'manufacturing',
    getTicketSavedSearches: 'manufacturing',
    labPortalDesignOrderCounts: 'manufacturing',
    labPortalDesignOrdersByListView: 'manufacturing',
    labPortalDesignReviseOrdersByListView: 'manufacturing',
    labPortalDesignSendToFloorOrdersByListView: 'manufacturing',
    labPortalDesignWaitForDandyOrdersByListView: 'manufacturing',
    labPortalIntakeOrderCounts: 'manufacturing',
    labPortalIntakeOrdersWithDesignByListView: 'manufacturing',
    labPortalIntakeOrdersWithReturnByListView: 'manufacturing',
    labPortalIntakeOrdersWithoutDesignByListView: 'manufacturing',
    labPortalIntakeOrdersWithViewedDocsByListView: 'manufacturing',
    labPortalNestedIntakeOrdersByListView: 'manufacturing',
    labPortalAutoNestingInProgressOrdersByListView: 'manufacturing',
    labPortalNewNeedsManualEntryOrdersByListView: 'manufacturing',
    labPortalNewOrderCounts: 'manufacturing',
    labPortalNewOrdersByListView: 'manufacturing',
    labPortalNewAcceptedOrdersWithoutLabSlipViewByListView: 'manufacturing',
    labPortalNewOrdersWithDesignByListView: 'manufacturing',
    labPortalNewOrdersWithReturnByListView: 'manufacturing',
    labPortalNewOrdersWithoutDesignByListView: 'manufacturing',
    labPortalOnHoldOrderCounts: 'manufacturing',
    labPortalOnHoldOrderCountsNullable: 'manufacturing',
    labPortalOnHoldOrdersByListView: 'manufacturing',
    labPortalOrderDetails: 'manufacturing',
    getOrderIdByStackCode: 'manufacturing',
    labPortalOrderUpdatesByListView: 'manufacturing',
    labPortalOverview: 'manufacturing',
    labPortalPullFromFloorUpdatesByListView: 'manufacturing',
    labPortalReturnsPendingOrdersByListView: 'manufacturing',
    labPortalReturnsOrderCounts: 'manufacturing',
    labPortalShipTodayLateOrdersByListView: 'manufacturing',
    labPortalShipTodayOrderCounts: 'manufacturing',
    labPortalShipTodayOrdersByListView: 'manufacturing',
    labPortalShipTodayOvernightOrdersByListView: 'manufacturing',
    labPortalSidebarCounts: 'manufacturing',
    labPortalUpdatesOrderCounts: 'manufacturing',
    lab_order: 'manufacturing',
    labtracIdForOrder: 'manufacturing',
    listAutomationActions: 'manufacturing',
    listCategories: 'manufacturing',
    listDisplayRuleOptions: 'manufacturing',
    listLabtracProductCodes: 'manufacturing',
    listManufacturerPreferences: 'manufacturing',
    listManufacturerProfiles: 'manufacturing',
    listOrderAutomations: 'manufacturing',
    listOrderFilters: 'manufacturing',
    listOrderReturns: 'manufacturing',
    listQCItemConfigs: 'manufacturing',
    listQcIssues: 'manufacturing',
    listRefabReasons: 'manufacturing',
    listShipments: 'manufacturing',
    listWorkflowStepDefinitions: 'manufacturing',
    loadOrderFilterOptions: 'manufacturing',
    manufacturerUpdatesScreenCount: 'manufacturing',
    orderEntriesByListView: 'manufacturing',
    orderInboxItems: 'manufacturing',
    orderWorkflowTasksByListView: 'manufacturing',
    orderWorkflowTasksCount: 'manufacturing',
    ordersByIds: 'manufacturing',
    ordersByListView: 'manufacturing',
    ordersForChairsideCache: 'manufacturing',
    ordersByScanId: 'manufacturing',
    ordersForLabSlipByIds: 'manufacturing',
    ordersMatchingAutomationFilterCount: 'manufacturing',
    panLabelsByLabOrderIds: 'manufacturing',
    pendingReturnForOrder: 'manufacturing',
    refabReasonsForOrderItems: 'manufacturing',
    resolveDefaultActionTimes: 'manufacturing',
    routingTargetDistributionRules: 'manufacturing',
    tags: 'manufacturing',
    timelineItems: 'manufacturing',
    validateDeliveryAddress: 'manufacturing',
    getAlignerRetainerSummaryForOrder: 'ordering',
    listDesignOrderDoctorReviews: 'cad_3d',
    getActiveScanReviewByLabOrderId: 'cad_3d',
    getAllDesignOrderAlignerTreatmentPlans: 'cad_3d',
    getLatestDoctorVisibleDesignOrderAlignerTreatmentPlan: 'cad_3d',

    // production management
    getAllCapacityStations: 'production_management',
    getAllCapacityStationRules: 'production_management',
    getAllProductStationUnitMappings: 'production_management',
    getAllActiveCapacityStationPools: 'production_management',
    getHoldRemovalSuggestedStageEndDate: 'production_management',
    getLegacyMultiFulfillmentInfoByOrderId: 'production_management',
    getOrderAdminListViewEntriesByIds: 'production_management',
    getOrderTrackerEntries: 'production_management',
    getProductionOrderById: 'production_management',
    getPracticeProductionOrderOverview: 'production_management',
    getProductionPlanForOrder: 'production_management',
    listManufacturerTruckTimeSupportedCarriers: 'production_management',
    listManufacturerTruckTimes: 'production_management',
    previewProductionPlanForLabOrder: 'production_management',
    previewUpdateOrderSla: 'production_management',
    previewUpdateRushConfiguration: 'production_management',
    salesOrderGetById: 'production_management',
    salesOrderGetIdByOrderNumber: 'production_management',
    salesOrderGetBySplitId: 'production_management',
    salesOrderGetInRefabChain: 'production_management',
    tatGetById: 'production_management',
    tatGetByOrderId: 'production_management',
    tatListAddOnLeadTimes: 'production_management',
    tatListItemLeadTimes: 'production_management',
    tatMakePlan: 'production_management',
    tatListItemLeadTimeOverrides: 'production_management',
};

export const deprecatedMutationMap = {
    setAlignerManufacturerTreatmentPlan: 'ordering',
    submitPracticeFeedback: 'ordering',
    acceptAutomateDesignRevision: 'cad_3d',
    notifyQCOrderError: 'manufacturing',
    skipOnboarding: 'practice_admin',
} satisfies Partial<MutationOwnershipMap>;

export const mutationOwnershipMap: MutationOwnershipMap = {
    ...deprecatedMutationMap,

    // billing
    addPriceApplicationRule: 'billing',
    agreeToCreditCardFees: 'billing',
    applyInvoiceCreditToInvoice: 'billing',
    applyPricePreset: 'billing',
    bulkOverrideLabUnitPrices: 'billing',
    bulkRegisterInvoiceItems: 'billing',
    bulkToggleWillBeChargedCCFee: 'billing',
    bulkUpdateOrderPrices: 'billing',
    bulkUpdatePartnerBillingConfigFlag: 'billing',
    bulkUpsertLabPriceLabPrices: 'billing',
    bulkUpsertOrganizationPrices: 'billing',
    bulkUpsertPrices: 'billing',
    changePriceName: 'billing',
    collectPartialPayment: 'billing',
    configureLabPriceLabPrices: 'billing',
    configureLabPricePartnerPrices: 'billing',
    createBillingCreditCategory: 'billing',
    createEnableCcFeeForAllWorkflow: 'billing',
    createInvoice: 'billing',
    createInvoiceCredit: 'billing',
    createLabCaseCompletion: 'billing',
    createLabCaseLedgerEntry: 'billing',
    createLabMetalCost: 'billing',
    createLabPrice: 'billing',
    createLabPriceLedgerEntry: 'billing',
    createLabPriceLedgerPartnerEntry: 'billing',
    createMetalMaterial: 'billing',
    createOrderPriceBackfillWorkflow: 'billing',
    createOrderPriceEntry: 'billing',
    createOrganizationPriceConfig: 'billing',
    createPartnerBilling: 'billing',
    createPaymentMethod: 'billing',
    createPrice: 'billing',
    createPricePreset: 'billing',
    deleteInvoiceCredit: 'billing',
    deleteMetalMaterial: 'billing',
    deleteOrderPriceEntry: 'billing',
    deleteOrganizationPriceConfig: 'billing',
    deletePartnerPriceConfig: 'billing',
    deletePrice: 'billing',
    deletePricePreset: 'billing',
    deleteRegisteredInvoiceItem: 'billing',
    editAccountManagementContacts: 'billing',
    editBillingContacts: 'billing',
    forceReturnRefabConversion: 'ordering',
    optPracticeInToCreditCardSurcharge: 'billing',
    optPracticeOutOfCreditCardSurcharge: 'billing',
    overrideDentistAmountDue: 'billing',
    overrideLabCaseUnitPrice: 'billing',
    payContractInvoicesWithPaymentSource: 'billing',
    payInvoiceWithPaymentSource: 'billing',
    payInvoiceWithConfig: 'billing',
    queueZeroOutModelsBulkPricingJob: 'billing',
    recordActivationFee: 'billing',
    recordPartnerActivationDate: 'billing',
    recordUpgradeFee: 'billing',
    registerActivationFeeCharge: 'billing',
    registerInvoiceItem: 'billing',
    removePaymentMethod: 'billing',
    removePriceApplicationRule: 'billing',
    removeUsagePaymentSplitConfig: 'billing',
    setDefaultPaymentMethod: 'billing',
    setPriceSalesforceMapping: 'billing',
    setSalesforcePriceMapping: 'billing',
    setUsagePaymentSplitConfigDoctor: 'billing',
    setUsagePaymentSplitConfigLocation: 'billing',
    setUsagePaymentSplitConfigPercent: 'billing',
    setWillBeChargedCcFee: 'billing',
    syncOrderPrices: 'billing',
    syncPricePresetsToSalesforce: 'billing',
    toggleLabCaseLedgerEntryReviewed: 'billing',
    toggleLabLedgerEntryReviewed: 'billing',
    toggleLabPriceDeletion: 'billing',
    updateBillingCreditCategory: 'billing',
    updateInvoiceCredit: 'billing',
    updateLabCaseLedgerEntry: 'billing',
    updateLabPrice: 'billing',
    updateOrderItemPennyweightsV2: 'billing',
    updateOrderPriceEntry: 'billing',
    updateOrganizationPriceConfig: 'billing',
    updatePaymentAmount: 'billing',
    updatePricePreset: 'billing',
    updatePrimaryBillingContactUser: 'billing',
    updatePrimaryBillingContactUserId: 'billing',
    updateRegisteredInvoiceItem: 'billing',
    updateStripeLinking: 'billing',
    upsertMetalCostsBulk: 'billing',
    upsertPartnerPriceConfig: 'billing',
    voidInvoice: 'billing',
    refundInvoicePayment: 'billing',
    payInvoiceWithManualStripePayment: 'billing',
    sendInvoiceSummaryEmail: 'billing',
    createAttributedCredits: 'billing',
    createRefundCategory: 'billing',
    updateRefundCategory: 'billing',
    issueAttributedRefunds: 'billing',
    bulkCreateCreditsWorkflow: 'billing',
    createCrownProductCatalogEntry: 'billing',
    modifyCrownProductCatalogEntry: 'billing',
    archiveProductCatalogEntry: 'billing',
    setPracticeContractV2EndDate: 'billing',
    emergencyOnlyHardDeleteContract: 'billing',
    removeInvoiceItem: 'billing',
    activatePracticeContract: 'billing',
    createOrderItemVariantCharge: 'billing',
    updateAndCreateOrderPrices: 'billing',

    // practice_admin
    addCRMTicketTags: 'practice_admin',
    addEntityTag: 'practice_admin',
    addExternalStaffMember: 'practice_admin',
    addInternalStaffMember: 'practice_admin',
    addLabStaffMember: 'practice_admin',
    addParentStaffMember: 'practice_admin',
    addPhoneToDrPrefAndUser: 'practice_admin',
    addPracticeStaffMember: 'practice_admin',
    bulkCreateUsers: 'practice_admin',
    bulkRemoveStaffMembers: 'practice_admin',
    bulkTagEntities: 'practice_admin',
    cancelLiveCasePlanningRequest: 'practice_admin',
    cancelLiveDdpReviewRequest: 'practice_admin',
    cancelLiveDdpReviewRequestByEventId: 'practice_admin',
    changePreferenceStaffMember: 'practice_admin',
    collectNotificationPreferences: 'practice_admin',
    convertPracticeToParent: 'practice_admin',
    createCRMChatMessage: 'practice_admin',
    createCRMTicket: 'practice_admin',
    createCRMTicketComment: 'practice_admin',
    createCRMUser: 'practice_admin',
    createChatMessage: 'practice_admin',
    createDoctorPreferences: 'practice_admin',
    createExternalOrg: 'practice_admin',
    createLiveCasePlanningRequest: 'practice_admin',
    createLiveDdpReviewRequest: 'practice_admin',
    createNonDoctorPracticeStaff: 'practice_admin',
    createOrganization: 'practice_admin',
    createPassword: 'practice_admin',
    createParentOrg: 'practice_admin',
    createPartner: 'practice_admin',
    createPracticeSupport: 'practice_admin',
    createTag: 'practice_admin',
    createTrainingIntakeResponse: 'practice_admin',
    createUser: 'practice_admin',
    deleteChatMessage: 'practice_admin',
    deleteDoctorPreferences: 'practice_admin',
    deleteNonDoctorPracticeStaff: 'practice_admin',
    deletePracticeSupport: 'practice_admin',
    deleteTag: 'practice_admin',
    editTag: 'practice_admin',
    linkOrganizationExternalAccount: 'practice_admin',
    markEventUnread: 'practice_admin',
    markEventsRead: 'practice_admin',
    migratePreferencesToStaff: 'practice_admin',
    oneTimeCodeLoginV2: 'practice_admin',
    oneTimeCodeVerification: 'practice_admin',
    patient_portal_link: 'practice_admin',
    reactivateDoctorPreferences: 'practice_admin',
    reactivateOrganizationStaffMember: 'practice_admin',
    activateOrganizationStaffMember: 'practice_admin',
    removeEntityTag: 'practice_admin',
    removeOrganizationStaffMember: 'practice_admin',
    removePreferenceStaffMember: 'practice_admin',
    removeSendersFromPref: 'practice_admin',
    requestPrecheckoutScanReview: 'practice_admin',
    salesforceUserSync: 'practice_admin',
    sendAttachPhotosUploadLink: 'practice_admin',
    sendDoctorPrefsUpdatedNotification: 'practice_admin',
    sendInviteEmail: 'practice_admin',
    sendMobileAppLinks: 'practice_admin',
    sendOneTimeCode: 'practice_admin',
    sendResetPasswordEmail: 'practice_admin',
    setChatMessageVisibility: 'practice_admin',
    setDoctorNotificationPrefs: 'practice_admin',
    setDoctorPreferences: 'practice_admin',
    setDoctorPrefsForwarding: 'practice_admin',
    setDoctorProfilePhoto: 'practice_admin',
    setNonDoctorPracticeStaff: 'practice_admin',
    setPartnerNotificationPreferences: 'practice_admin',
    setPreferredManufacturers: 'practice_admin',
    setReceiversOnPref: 'practice_admin',
    sharePatientScan: 'practice_admin',
    tagEntity: 'practice_admin',
    undeleteTag: 'practice_admin',
    unlinkOrganizationExternalAccount: 'practice_admin',
    untagEntity: 'practice_admin',
    updateCRMChatConversation: 'practice_admin',
    updateCRMTicket: 'practice_admin',
    updateExternalStaffMemberRoles: 'practice_admin',
    updateInternalStaffMemberRoles: 'practice_admin',
    updateLabOrderLockStatus: 'practice_admin',
    updateLabStaffMemberRoles: 'practice_admin',
    updateOrganizationName: 'practice_admin',
    updateOrganizationPhoneNumber: 'practice_admin',
    updateOrganizationParentId: 'practice_admin',
    updateOrganizationSettings: 'practice_admin',
    updateOrganizationStaffMemberZendeskId: 'practice_admin',
    updatePracticeSettings: 'practice_admin',
    updateOrganizationType: 'practice_admin',
    updateParentStaffMemberRoles: 'practice_admin',
    updatePracticeStaffMemberRoles: 'practice_admin',
    updatePracticeSupport: 'practice_admin',
    updateStaffMemberOnboardingPreferences: 'practice_admin',
    updateStaffMemberOnboardingPreferencesById: 'practice_admin',
    updateUser: 'practice_admin',
    updateUserLoginPhone: 'practice_admin',
    updateUserPassword: 'practice_admin',
    verifyLoginPhoneNumber: 'practice_admin',
    startOnboarding: 'practice_admin',
    skipOnboardingV2: 'practice_admin',
    completeOnboarding: 'practice_admin',
    transitionStep: 'practice_admin',
    setPracticeScanStart: 'practice_admin',
    postCreatePracticeOnboarding: 'practice_admin',
    bulkUpdateOrganizationIsFakeStatus: 'practice_admin',
    updateOrCreateIteroCompany: 'practice_admin',
    updateOrCreateIteroUser: 'practice_admin',
    transitionOnboardingStep: 'practice_admin',
    createVideoDdpReviewRequest: 'practice_admin',
    fulfillVideoDdpReviewRequest: 'practice_admin',
    cancelLiveLabTechMeetingRequest: 'practice_admin',
    createLiveLabTechMeetingRequest: 'practice_admin',
    updateLiveLabTechMeetingRequestNotes: 'practice_admin',

    // product_lines
    createEmptyVariantFeedback: 'product_lines',
    createGoldilocksControlGroupMetadata: 'product_lines',
    submitVariantFeedback: 'product_lines',
    createShadeSuggestionWithPhotos: 'product_lines',

    // ordering
    addAbutmentPartToImplantScanbody: 'ordering',
    addLabOrderPhotos: 'ordering',
    addLabOrderPhotosWithNotes: 'ordering',
    addOrderItem: 'ordering',
    addOrderItemsV2: 'ordering',
    addReasonCodeRule: 'ordering',
    addRetainerToAlignerCase: 'ordering',
    approveAlignerFabrication: 'ordering',
    archiveAbutmentPart: 'ordering',
    attachExternalAlignerFulfillment: 'ordering',
    attachScanStlFiles: 'ordering',
    attachTreatmentPlanRequest: 'ordering',
    bulkCreateScanbodyRequests: 'ordering',
    cancelAndResubmit: 'ordering',
    carestreamPreUpload: 'ordering',
    changeDeliveryAddress: 'ordering',
    changeDesignSoftware: 'ordering',
    completeTreatmentPlanConversion: 'ordering',
    continueDenturesFulfillmentWaxRim: 'ordering',
    createAbutmentPart: 'ordering',
    createCaseReadiness: 'ordering',
    createChairsideReviewSubmission: 'ordering',
    createCompletedScanningCase: 'ordering',
    createDoctorRequest: 'ordering',
    createOrFindChairsidePatient: 'ordering',
    createPatient: 'ordering',
    createPendingAlignerCase: 'ordering',
    createPracticeCaseBulk: 'ordering',
    createPracticeRequestMarketingMaterials: 'ordering',
    createPracticeRequestScheduleTraining: 'ordering',
    createReasonCode: 'ordering',
    createReturnForRefabrication: 'ordering',
    createReturnForTryIn: 'ordering',
    createReviewSubmissionV3: 'ordering',
    createReviewTag: 'ordering',
    createScanExportPreUpload: 'ordering',
    createScanExportPreUploadScanner: 'ordering',
    createScanExportPreUploadWithRaw: 'ordering',
    createScanExportPreUploadWithRawScanner: 'ordering',
    createScanbody: 'ordering',
    createScanbodyRequest: 'ordering',
    createScanner: 'ordering',
    createTraining: 'ordering',
    createTryInFeedback: 'ordering',
    deleteOrderItem: 'ordering',
    deleteReasonCodeRule: 'ordering',
    deleteScanExport: 'ordering',
    deleteScanbody: 'ordering',
    deleteScanbodyRequest: 'ordering',
    dev_associate_treatment_plan_with_case: 'ordering',
    editAlignerCase: 'ordering',
    finalizeTreatmentPlanRequest: 'ordering',
    generateSoftsmileWebviewerLink: 'ordering',
    generate_softsmile_vision_link: 'ordering',
    linkScanbodyRequestFulfillment: 'ordering',
    mobileUploadLoaded: 'ordering',
    openPendingAlignerCaseEstimateModal: 'ordering',
    overridePendingAlignerPatientPrice: 'ordering',
    placeDenturesFulfillmentCore: 'ordering',
    placeDenturesFulfillmentWaxRim: 'ordering',
    placeLabOrderFromTryIn: 'ordering',
    placeOrder: 'ordering',
    practiceRespondToDoctorRequest: 'ordering',
    process3oxzFile: 'ordering',
    process3oxzFileScanner: 'ordering',
    processThreeshapeScanNotifications: 'ordering',
    refreshImagesForProcessedScan: 'ordering',
    rejectPendingAlignerCase: 'ordering',
    removeAbutmentPartToImplantScanbody: 'ordering',
    removeScannerOwner: 'ordering',
    replaceThreeoxz: 'ordering',
    requestOrderRefinement: 'ordering',
    restoreScanbody: 'ordering',
    schedule3oxzToStlConversion: 'ordering',
    sendCheckoutImagesUploadLink: 'ordering',
    sendRefabFilesUploadLink: 'ordering',
    sendScanbodySurgicalReportUploadLink: 'ordering',
    setCbctScanUrl: 'ordering',
    setDoctorPreferencesId: 'ordering',
    setPartnerScanners: 'ordering',
    setScannerOwner: 'ordering',
    setSingleClinicalPreference: 'ordering',
    setSmileStyle: 'ordering',
    setTreatmentPlanRequestUrl: 'ordering',
    submitPendingAlignerCaseEstimate: 'ordering',
    submitQcFlag: 'ordering',
    unarchiveAbutmentPart: 'ordering',
    updateAbutmentPart: 'ordering',
    updateAbutmentPartToImplantScanbody: 'ordering',
    updateCurrentRetainer: 'ordering',
    updateIteroSettingsOfCurrentUser: 'ordering',
    updateOrderItemsV2: 'ordering',
    updateOrderItemsV2Practice: 'ordering',
    updatePartialDentureMetadata: 'ordering',
    updateReasonCode: 'ordering',
    updateReviewSubmissionItemTags: 'ordering',
    updateReviewTag: 'ordering',
    updateScanbody: 'ordering',
    updateScanbodyRequest: 'ordering',

    // platform
    bulkDeleteAutomations: 'platform',
    bulkRestoreAutomations: 'platform',
    createRebuildAggregateByIdsWorkflow: 'platform',
    createRebuildAggregateWorkflow: 'platform',
    executeTemporalWorkflow: 'platform',
    recomputeOrder: 'platform',

    // cad_3d
    addCadSmileLibraryRevision: 'cad_3d',
    createCadSmileLibraryWithInitialRevision: 'cad_3d',
    createCadSmileLibrary: 'cad_3d',
    archiveCadSmileLibrary: 'cad_3d',
    unarchiveSmileLibrary: 'cad_3d',
    archiveThreeshapeDme: 'cad_3d',
    attachAlignerPreppedScans: 'cad_3d',
    attachDesignAsset: 'cad_3d',
    attachDesignFiles: 'cad_3d',
    bulkCreateDesignOrders: 'cad_3d',
    bulkUpdateAbutmentPartScrewSku: 'cad_3d',
    cancelDesignQaEvaluations: 'cad_3d',
    cancelOrderAutomateTask: 'cad_3d',
    completeDesignPrepTask: 'cad_3d',
    completeDesignReviewTask: 'cad_3d',
    completeExternalDesignVerificationTask: 'cad_3d',
    completeInternalDesignTask: 'cad_3d',
    completeModelDesignTask: 'cad_3d',
    configureDesignReview2Command: 'cad_3d',
    configureOrderDesignerType: 'cad_3d',
    configureOrderDesignReview: 'cad_3d',
    configureOrderWaxup: 'cad_3d',
    createDesignQaEvaluation: 'cad_3d',
    createDesignReviewField: 'cad_3d',
    createImplantType: 'cad_3d',
    createThreeshapeDme: 'cad_3d',
    deleteImplantType: 'cad_3d',
    enrollInDesignTrainingCourse: 'cad_3d',
    generateEmptyDesignFile: 'cad_3d',
    getDownloadUrl: 'cad_3d',
    logThreeshapePluginOperation: 'cad_3d',
    manuallyAttachInjectionMoldFiles: 'cad_3d',
    promoteDesignRevisionToLatest: 'cad_3d',
    recordDesignDownload: 'cad_3d',
    replaceRemovableManufacturingFiles: 'cad_3d',
    reprocessThreeshapeDmes: 'cad_3d',
    rerunDesignConversion: 'cad_3d',
    mergeAndStitchDesignOrders: 'cad_3d',
    restoreImplantType: 'cad_3d',
    sendOrderToAutomate: 'cad_3d',
    resendOrdersToAutomate: 'cad_3d',
    skipCase: 'cad_3d',
    startDesignEditorSession: 'cad_3d',
    startPrepInBrowser: 'cad_3d',
    submitAutomateReview: 'cad_3d',
    submitAutomateReviewForTraining: 'cad_3d',
    submitDesignEditor: 'cad_3d',
    submitDesignEditorForTraining: 'cad_3d',
    submitDesignQaEvaluation: 'cad_3d',
    submitFinishingInBrowser: 'cad_3d',
    submitFinishingInBrowserForTraining: 'cad_3d',
    submitPrepInBrowser: 'cad_3d',
    submitPrepInBrowserForTraining: 'cad_3d',
    submitWaxupReview: 'cad_3d',
    unarchiveThreeshapeDme: 'cad_3d',
    updateDesignReviewField: 'cad_3d',
    updateImplantType: 'cad_3d',
    updateOrderClinicalSupportNotes: 'cad_3d',
    updateThreeshapeDmeFilePath: 'cad_3d',
    withdrawFromDesignTrainingCourse: 'cad_3d',

    // manufacturing
    invalidateInternalRemake: 'manufacturing',
    recordInternalRemake: 'manufacturing',
    recordPackingCompletedForManufacturingOrders: 'manufacturing',
    recordShippingDeptScanAndFetchBatchCandidates: 'manufacturing',
    recordShippingDeptScanAndFetchBatchDecision: 'manufacturing',
    acknowledgeAllLabUpdates: 'manufacturing',
    acknowledgeLabUpdatesForOrder: 'manufacturing',
    acknowledgeManualEntryLmsOrder: 'manufacturing',
    addLabSlipAlert: 'manufacturing',
    addManualQualityControlItem: 'manufacturing',
    addOrderToShipment: 'manufacturing',
    addRefabReasonRule: 'manufacturing',
    appendTicketAction: 'manufacturing',
    archiveOrderAutomation: 'manufacturing',
    archiveQcItemConfig: 'manufacturing',
    assignFulfillmentTask: 'manufacturing',
    attachQcPhotos: 'manufacturing',
    batchOrderShipments: 'manufacturing',
    batchOrderShipmentsAndRecordPackingCompleted: 'manufacturing',
    blockStacks: 'manufacturing',
    cancelManufacturingOrder: 'manufacturing',
    cancelOrderReturn: 'manufacturing',
    cancelShipment: 'manufacturing',
    changeLabOrderManufacturer: 'manufacturing',
    changeManufacturerBulk: 'manufacturing',
    claimTicketAction: 'manufacturing',
    closeTicketAction: 'manufacturing',
    completeQualityControlItem: 'manufacturing',
    confirmOrderReturnReceived: 'manufacturing',
    createActionCapability: 'manufacturing',
    createActionCategory: 'manufacturing',
    createActionType: 'manufacturing',
    createBiasRule: 'manufacturing',
    createCapacityRule: 'manufacturing',
    createCategory: 'manufacturing',
    createDeliveryAddress: 'manufacturing',
    createDesignRule: 'manufacturing',
    createFabricationBulk: 'manufacturing',
    createFulfillment: 'manufacturing',
    createLabsManufacturer: 'manufacturing',
    createManualLabOrderIntegrationAttempt: 'manufacturing',
    createManufacturingBookings: 'manufacturing',
    createOrderAutomation: 'manufacturing',
    createOrderHold: 'manufacturing',
    createQcIssue: 'manufacturing',
    createQcItemConfig: 'manufacturing',
    createRefabReason: 'manufacturing',
    createRoutingTargetDistribution: 'manufacturing',
    createSavedInboxSearch: 'manufacturing',
    createSavedOrderSearch: 'manufacturing',
    createSavedRoutingSearch: 'manufacturing',
    createShippingLabel: 'manufacturing',
    createTicket: 'manufacturing',
    closeTicket: 'manufacturing',
    createTicketSavedSearch: 'manufacturing',
    createWorkflowStepDefinition: 'manufacturing',
    deleteOrderAutomation: 'manufacturing',
    deleteRefabReasonRule: 'manufacturing',
    deleteSavedSearch: 'manufacturing',
    deleteTicketSavedSearch: 'manufacturing',
    disableBiasRule: 'manufacturing',
    disableCapacityRule: 'manufacturing',
    disableDesignRule: 'manufacturing',
    deleteManufacturingTechnicians: 'manufacturing',
    disableRoutingTargetDistributionRule: 'manufacturing',
    editDeliveryAddress: 'manufacturing',
    editDoctorNote: 'manufacturing',
    editQcItemConfig: 'manufacturing',
    editRefabrication: 'manufacturing',
    editRefabricationReasons: 'manufacturing',
    editSavedInboxSearch: 'manufacturing',
    editSavedOrderSearch: 'manufacturing',
    editSavedRoutingSearch: 'manufacturing',
    editTicketActionNote: 'manufacturing',
    editTicketSavedSearch: 'manufacturing',
    emailReturnLabel: 'manufacturing',
    enableBiasRule: 'manufacturing',
    enableCapacityRule: 'manufacturing',
    enableDesignRule: 'manufacturing',
    enableRoutingTargetDistributionRule: 'manufacturing',
    ensureStackCodesForLabOrders: 'manufacturing',
    externalUserCancelOrder: 'manufacturing',
    favoriteSavedSearch: 'manufacturing',
    favoriteTicketSavedSearch: 'manufacturing',
    flagOrderForReview: 'manufacturing',
    ignoreQualityControlItem: 'manufacturing',
    labOrderQcStationFail: 'manufacturing',
    labOrderQcStationPass: 'manufacturing',
    linkOrderToRefabrication: 'manufacturing',
    markDelivered: 'manufacturing',
    markNeedsRefabrication: 'manufacturing',
    markOrdersIntakeCompletedByLabOrderIds: 'manufacturing',
    markMfgUnitsAutoNestingCompleteWithDetailedResponse: 'manufacturing',
    markReturnReceivedBulk: 'manufacturing',
    markShipped: 'manufacturing',
    modifyOrderSla: 'manufacturing',
    opsCancelOrder: 'manufacturing',
    overrideNonProdManufacturer: 'manufacturing',
    practiceRespondToRejectedScan: 'manufacturing',
    printableLabSlipViewed: 'manufacturing',
    queueManufacturingFileCleanUp: 'manufacturing',
    queueManufacturingFileProcessing: 'manufacturing',
    recordDesignDownloadedManufacturerBulk: 'manufacturing',
    recordLabSlipsViewedByLabOrderIds: 'manufacturing',
    recordPackingSheetViewed: 'manufacturing',
    refreshShipmentTracking: 'manufacturing',
    refreshShippingStatus: 'manufacturing',
    rejectTreatmentPlan: 'manufacturing',
    releaseTicketAction: 'manufacturing',
    removeFulfillmentShipping: 'manufacturing',
    removeLabSlipAlerts: 'manufacturing',
    removeOrderHold: 'manufacturing',
    removeOrderFromShipment: 'manufacturing',
    replaceRejectedScan: 'manufacturing',
    rerouteLabOrder: 'manufacturing',
    rerouteOrders: 'manufacturing',
    resetManufacturingBookings: 'manufacturing',
    resolveOrderReviewFlag: 'manufacturing',
    resolveOrderScanRejection: 'manufacturing',
    runManufacturingBookingStartSyncWorkflow: 'manufacturing',
    runManufacturingBookingSyncWorkflow: 'manufacturing',
    saveDoctorLabSlipAlerts: 'manufacturing',
    saveLabSlipAlerts: 'manufacturing',
    sendOrderToFloor: 'manufacturing',
    sendOrdersToFloorBulk: 'manufacturing',
    setManufacturerNotificationPreferences: 'manufacturing',
    setOrderAutomationFolder: 'manufacturing',
    shareScanRejectionWithPractice: 'manufacturing',
    skipManufacturingBookings: 'manufacturing',
    skipMillingAutoNestingForAllOrders: 'manufacturing',
    skipMillingAutoNestingForOrder: 'manufacturing',
    startFulfillmentTask: 'manufacturing',
    startOrderIntegrationManualV2: 'manufacturing',
    submitOrderScanReview: 'manufacturing',
    testInternalLabtracIntegrationV2: 'manufacturing',
    testOrderAutomation: 'manufacturing',
    toggleArchiveCategory: 'manufacturing',
    toggleDeliveryAddressDeleted: 'manufacturing',
    unblockStacks: 'manufacturing',
    undoLinkOrderToRefabrication: 'manufacturing',
    unfavoriteSavedSearch: 'manufacturing',
    unfavoriteTicketSavedSearch: 'manufacturing',
    updateActionCapability: 'manufacturing',
    updateActionCategory: 'manufacturing',
    updateActionType: 'manufacturing',
    updateBiasRule: 'manufacturing',
    updateCapacityRule: 'manufacturing',
    updateCategory: 'manufacturing',
    updateDesignRule: 'manufacturing',
    updateLabOrderSplits: 'manufacturing',
    updateOrderAutomation: 'manufacturing',
    updateOrderHold: 'manufacturing',
    updateQcIssue: 'manufacturing',
    updateRefabReason: 'manufacturing',
    updateRoutingTargetDistributionRule: 'manufacturing',
    updateWorkflowStepDefinition: 'manufacturing',
    upsertManufacturerProfile: 'manufacturing',
    handleFcmCheck: 'practice_admin',
    handleFcmCheckByCaseInfo: 'practice_admin',
    submitDesign: 'cad_3d',
    nonProductionAutoSubmitDesign: 'cad_3d',
    updatePracticeOrderingPauseReasons: 'billing',
    bulkBackfillDesignOrderDoctorReviews: 'cad_3d',

    // production management
    bulkCreateDesignStaffTeams: 'production_management',
    bulkDeleteDesignStaffTeams: 'production_management',
    bulkDownloadDesignStaffRoster: 'production_management',
    bulkUpdateDesignStaffRoster: 'production_management',
    createAndDeleteFocusAreas: 'production_management',
    createFabricationBulkInternal: 'production_management',
    createCapacityStation: 'production_management',
    createCapacityStationRule: 'production_management',
    createDesignStaffTeam: 'production_management',
    createProductStationUnitMapping: 'production_management',
    updateCapacityStationPool: 'production_management',
    createManufacturerTruckTime: 'production_management',
    deleteManufacturerTruckTime: 'production_management',
    offboardDesignStaff: 'production_management',
    optOutProductionOrder: 'production_management',
    preemptDesignTaskTimeout: 'production_management',
    reOnboardDesignStaff: 'production_management',
    restoreManufacturerTruckTime: 'production_management',
    runRescheduleAndReassign: 'production_management',
    salesOrderAddItems: 'production_management',
    salesOrderDeleteItem: 'production_management',
    salesOrderUpdateItems: 'production_management',
    salesOrderChangeDoctorPreferences: 'production_management',
    salesOrderChangeDeliveryAddress: 'production_management',
    setDesignStaffPrepCapabilities: 'production_management',
    tatMigrate: 'production_management',
    tatMigrateWorkflow: 'production_management',
    tatSync: 'production_management',
    tatUpsertAddOnLeadTime: 'production_management',
    tatUpsertItemLeadTime: 'production_management',
    tatUpsertItemLeadTimeOverride: 'production_management',
    updateDesignStaffRosterInfo: 'production_management',
    updateDesignStaffStatus: 'production_management',
    upsertDesignStaff: 'production_management',
    updateManufacturerTruckTime: 'production_management',
    updateRushConfiguration: 'production_management',
    updateProductStationUnitMappingUnits: 'production_management',
    deleteProductStationUnitMapping: 'production_management',
    updateCapacityStation: 'production_management',
    updateCapacityStationRule: 'production_management',
    disableCapacityStationRule: 'production_management',
};

export const GqlOperationOwnershipMap: Record<string, DandyEngTeam> = { ...queryOwnershipMap, ...mutationOwnershipMap };

export interface GQLTracingTags {
    // team that owns the first query field in the operation
    GQL_OPERATION_TEAM: DandyEngTeam | 'NOT_SET';
    // query or mutation
    GQL_OPERATION_TYPE: string;
    // named operation. example: `query MyQuery { listStuff { id } }` would be "MyQuery"
    GQL_OPERATION: string;
    // top level selection fields. Using above example, would be "listStuff"
    GQL_QUERY_FIELDS: string | null;
    // the return type(s) of the top-level operation(s). Using above example, would be "StuffDTO"
    GQL_RETURN_TYPES: string;
}

export function getGraphqlResolverOwner(resolverName: string | undefined): string | undefined {
    return resolverName ? GqlOperationOwnershipMap[resolverName] : undefined;
}

function getOperationReturnTypes(operationFieldNames: string[]): string {
    const returnTypes = operationFieldNames.flatMap(fieldName => GqlOperationReturnTypes[fieldName] ?? []);
    if (returnTypes.length === 0) {
        return 'unknown';
    }
    return returnTypes.join(',');
}

export function getGraphQLTracingTags(operation: OperationDefinitionNode): GQLTracingTags {
    try {
        const GQL_OPERATION_TYPE = operation.operation;
        const fields = operation.selectionSet.selections.flatMap(s => (s.kind === 'Field' ? [s.name.value] : []));
        const firstQueryField = fields[0];
        const GQL_QUERY_FIELDS = fields.join(',');
        return {
            GQL_OPERATION_TYPE,
            GQL_QUERY_FIELDS,
            GQL_OPERATION_TEAM: (firstQueryField && GqlOperationOwnershipMap[firstQueryField]) || 'NOT_SET',
            GQL_OPERATION: operation.name?.value ?? GQL_QUERY_FIELDS,
            GQL_RETURN_TYPES: getOperationReturnTypes(fields),
        };
        // no logical reason why this should ever fail, but just to be safe because its in critical path
        // EPDPLT-4736: Using any is unsafe and should be avoided.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
        console.error(e);
        return {
            GQL_OPERATION_TYPE: 'query',
            GQL_QUERY_FIELDS: null,
            GQL_OPERATION: 'unknown',
            GQL_OPERATION_TEAM: 'NOT_SET',
            GQL_RETURN_TYPES: 'unknown',
        };
    }
}
