import type { DesignTrainingOrderFragment } from '@orthly/graphql-inline-react';

export const isFinishingCourse = (trainingOrder: DesignTrainingOrderFragment) =>
    trainingOrder.type === 'AutomateReviewAndFinish';

export const isPrepCourse = (trainingOrder: DesignTrainingOrderFragment) => trainingOrder.type === 'Prep';

export const isDesignEditorCourse = (trainingOrder: DesignTrainingOrderFragment) =>
    trainingOrder.type === 'DesignEditor';

export const isFullDesignCourse = (trainingOrder: DesignTrainingOrderFragment) => trainingOrder.type === 'FullDesign';
